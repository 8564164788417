import { v4 as uuidv4 } from 'uuid';
import Parse from '../src/parse';
import { S3_URL_PATTERN } from '../constants/video';
import { uploadFile } from '../src/util/fileUploader';

export default {
  getKeyFromUrl(url) {
    return url && url.split(S3_URL_PATTERN)[1];
  },
  removeImage(url, objectInfo = {}) {
    const key = this.getKeyFromUrl(url);
    return key ? this.archiveFile(key, objectInfo) : false;
  },
  getFileUrl(key) {
    return Parse.Cloud.run('getFileUrl', { key });
  },
  archiveFile(key, objectInfo = {}) {
    return Parse.Cloud.run('archiveFile', { key, objectInfo });
  },
  generateBlob(file) {
    return new Promise((resolve) => {
      file.generateBlob((blob) => {
        try {
          resolve(new File([blob], 'cover.png', {
            type: blob.type, lastModified: Date.now()
          }));
        } catch (error) {
          console.log('Using blob, because of IE11 error:', error);
          resolve(blob);
        }
      }, 'image/jpeg', 1);
    });
  },
  async uploadBase64Image(url, dataUrl, acl, objectInfo = {}) {
    const key = `images/${uuidv4()}`;
    const result = await Parse.Cloud.run('base64Upload', { key, dataUrl, acl, objectInfo });

    // Only remove oldFile if upload successful
    if (result.location) {
      this.removeImage(url, objectInfo);
    }

    return result;
  },
  async uploadImage(file, url, acl = 'public-read', objectInfo, destination = null) {
    const blob = await this.generateBlob(file);
    let key;
    if (!destination) {
      key = `images/${uuidv4()}`;
    } else {
      key = `${destination}/${uuidv4()}`;
    }
    const result = await uploadFile({ file: blob, key, acl, objectInfo });

    // Only remove oldFile if upload successful
    if (result) {
      this.removeImage(url, objectInfo);
    }

    return result;
  }
};
