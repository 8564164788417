const state = {
  snackBar: {
    visible: false,
    text: null,
    timeout: 2500,
    multiLine: false,
  }
};

const getters = {
  snackBar: (st) => st.snackBar
};

const mutations = {
  showSnackBar(st, payload) {
    const { text, multiLine, timeout } = payload;

    st.snackBar = {
      text,
      multiLine: multiLine || (text.length > 50),
      timeout: timeout || 2500,
      visible: true
    };
  },
  hideSnackBar(st) {
    st.snackBar.visible = false;
  }
};

const actions = {
  showSnackBar ({ dispatch, commit }, payload) {
    commit('showSnackBar', payload);
    dispatch('hideSnackBar', payload.timeout);
  },
  hideSnackBar({ commit }, timeout) {
    setTimeout(() => {
      commit('hideSnackBar');
    }, timeout || 2500);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
