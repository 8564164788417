<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="users"
    :disabled="disabled"
    :filter="searchFilter"
    :items="source"
    :label="label"
    :multiple="multiple"
    :rules="rules"
    :search-input.sync="search"
    chips
    item-text="name"
    item-value="id"
    return-object
  >
    <template #selection="data">
      <v-chip
        :close="!disabled"
        class="chip--select-multi pl-0"
        input-value="data.selected"
        pill
        @click:close="removeUser(data.item)"
      >
        <v-avatar>
          <div
            :style="{backgroundImage: `url(${getUserImage(data.item.photo)}`}"
            class="avatar"
          />
        </v-avatar>
        <span class="pl-2">
          {{ data.item.name }}
        </span>
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item-avatar>
        <div
          :style="{backgroundImage: `url(${getUserImage(data.item.photo)}`}"
          class="avatar"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ data.item.name }}</span>
          <span v-if="showAdminRole">,
            {{ adminTypeToString(data.item.adminUserType) }}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.username || data.item.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { adminTypeToString } from '@kickbox/common-util/src/util/adminTypeToString';
import emptyAvatar from '@/assets/img/no-avatar-icon.svg';

export default {
  name: 'SelectUsers',
  props: {
    value: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    source: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: 'Coaches'
    },
    showAdminRole: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      users: this.value || (this.multiple ? [] : ''),
      search: ''
    };
  },
  watch: {
    value(users) {
      this.users = users;
    },
    users(users) {
      this.search = '';
      this.$emit('input', users);
    }
  },
  methods: {
    searchFilter(item, queryText) {
      const searchTerm = queryText.toLocaleLowerCase();
      return item?.name.toLocaleLowerCase().includes(searchTerm)
        || item.email?.toLocaleLowerCase().includes(searchTerm)
        || item.username?.toLocaleLowerCase().includes(searchTerm);
    },
    removeUser(user) {
      if (this.multiple) {
        const index = this.users.findIndex((c) => c.id === user.id);
        if (index >= 0) this.users.splice(index, 1);
      } else {
        this.users = null;
      }
    },
    getUserImage(image) {
      if (image) {
        // eslint-disable-next-line no-underscore-dangle
        return image._url || image;
      }
      return emptyAvatar;
    },
    validate() {
      // ============================================================
      // Custom validation for the component
      // Upon using this component use rules from Validate Mixin
      // See example usage in ManageEditCampaign
      // ============================================================
      const ruleNames = this.rules.map((_) => _.name);

      let valid = true;
      if (ruleNames.indexOf('required') >= 0
        || ruleNames.indexOf('atLeastOneItem')) {
        valid = this.value && !!this.value.length;
      } else {
        valid = true;
      }
      this.$refs.autocomplete.validate(!valid);
      return valid;
    },
    adminTypeToString
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
}
</style>
