<template>
  <v-snackbar
    :value="snackBar.visible"
    :multi-line="snackBar.multiline === true"
  >
    {{ snackBar.text }}
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackBar() {
      return this.$store.getters.snackBar;
    }
  }
};
</script>

<style>
</style>
