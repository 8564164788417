<template>
  <div
    class="stat-container"
    :style="{ width }"
  >
    <v-card
      v-ruid="`card-${keyMetric.value}`"
      class="stat-card"
      :class="{ selected, 'card-border': isLoading.other }"
      :style="{ backgroundColor: selected ? keyMetric.color : 'white' }"
      @click="openProjectsDrawer"
    >
      <v-row class="ma-0">
        <!-- Text of card -->
        <div class="text-container flex-grow-1">
          <template v-if="isLoading.projects || isLoading.users">
            <v-skeleton-loader
              class="mt-7"
              type="heading"
            />
            <v-skeleton-loader
              class="mr-6 mt-2"
              type="text"
            />
          </template>
          <template v-else>
            <p
              class="label"
            >
              {{ keyMetric.type === 'campaign' ? 'Campaign' : '' }}
            </p>
            <p
              v-ruid="keyMetric.value"
              class="amount"
            >
              {{ projects.length }}
            </p>
            <p
              class="label"
            >
              {{ keyMetric.text }}
            </p>
          </template>
        </div>

        <!-- Tooltip with Icon -->
        <v-tooltip
          bottom
          :disabled="!keyMetric.tooltip || isLoading.projects || isLoading.users"
          max-width="250"
          color="rgba(0, 0, 0, 0.8)"
        >
          <template #activator="{ on }">
            <div
              class="icon-container"
              v-on="on"
            >
              <v-skeleton-loader
                v-if="isLoading.projects || isLoading.users"
                type="avatar"
              />
              <div
                v-else
                class="icon-background"
                :style="{backgroundColor: keyMetric.color}"
              >
                <img
                  class="icon"
                  :src="imageUrl"
                >
              </div>
            </div>
          </template>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="keyMetric.tooltip" />
        </v-tooltip>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from './utils';
import phaseIcon from '../../assets/img/phase-icon.png';
import campaignIcon from '../../assets/img/campaign-icon.svg';

export default {
  props: {
    keyMetric: {
      type: Object,
      default: () => ({})
    },
    numberOfKeyMetrics: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selected: false
    };
  },
  computed: {
    ...mapGetters(['projectsDrawer', 'isLoading']),
    // Calculates the width of the key-metric card depending on the amount of key-metrics
    width() {
      if (this.$vuetify.breakpoint.smOnly) return '50%';
      if (this.$vuetify.breakpoint.xsOnly) return '100%';
      if (this.numberOfKeyMetrics === 3 || this.numberOfKeyMetrics === 6) return '33.3%';
      if (this.numberOfKeyMetrics === 4 || this.numberOfKeyMetrics === 8) return '25%';
      if (this.numberOfKeyMetrics === 5) return '20%';
      return '25%';
    },
    projects() {
      if (this.keyMetric.type === 'phase') {
        return utils.activeProjectsByPhase(this.keyMetric.value);
      }
      return utils.projectsByCampaign(this.keyMetric.value);
    },
    imageUrl() {
      if (this.keyMetric.type === 'phase') {
        return phaseIcon;
      }
      return campaignIcon;
    }
  },
  watch: {
    projectsDrawer() {
      // Set selected to false when the project drawer closes
      if (!this.projectsDrawer) {
        this.selected = false;
      }
    }
  },
  methods: {
    // Opens the project drawer and applies the projects of the selected key metric
    openProjectsDrawer() {
      if (!this.isLoading.projects) {
        this.selected = true;
        this.$store.commit('setProjectsDrawer', this.keyMetric);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.stat-container {
  padding: 20px;
}

.stat-card {
  box-shadow: 0 0 30px rgba(108, 108, 108, 0.13) !important;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 12px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    .label, .amount {
      color: white;
    }
  }
}

.text-container {
  max-width: calc(100% - 50px);
}

.label {
  font-size: 1rem;
  color: #717171;
  margin: 0;
  height: 1.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount{
  font-weight: 500;
  font-size: 2rem;
  color: rgb(100, 100, 100);
  margin: 0;
  line-height: 1em;
}

.icon-container {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  align-self: center;
  margin-left: auto;
  flex-direction: column
}

.icon {
  height: 60%;
  margin: 20% auto;
  display: block;
  z-index: 1;
}

.icon-background{
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.card-border {
  border: 1px solid #ecebeb;
}
</style>
