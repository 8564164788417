import AddIcon from './AddIcon';
import CampaignIcon from './CampaignIcon';
import CoachIcon from './CoachIcon';
import CoinsConvertionIcon from './CoinsConvertionIcon';
import DashboardIcon from './DashboardIcon';
import EmailIcon from './EmailIcon';
import FiltersIcon from './FiltersIcon';
import LampIcon from './LampIcon';
import LogoutIcon from './LogoutIcon';
import NotesIcon from './NotesIcon';
import OrderKickboxesIcon from './OrderKickboxesIcon';
import PeopleIcon from './PeopleIcon';
import ProjectIcon from './ProjectIcon';
import ServiceIcon from './ServiceIcon';
import SettingsIcon from './SettingsIcon';
import UnitIcon from './UnitIcon';
import InnovationIcon from './InnovationIcon';
import OnboardingRoomIcon from './OnboardingRoomIcon';
import Transformation from './Transformation';
import Coaching from './Coaching';
import EmployeeActivation from './EmployeeActivation';
import ProgramSuccess from './ProgramSuccess';
import Help from './help/Help';
import HelpInfo from './help/HelpInfo';
import HelpRequest from './help/HelpRequest';
import HelpBug from './help/HelpBug';
import CoachingHub from './CoachingHubIcon';
import Comments from './Comments';
import ArrowIcon from './ArrowIcon';
import BooksIcon from './BooksIcon';
import GroupArrowsIcon from './GroupArrowsIcon';
import PersonSignIcon from './PersonSignIcon';
import DumbbelIcon from './DumbbellIcon';
import MugHotIcon from './MugHotIcon';
import UsersIcon from './UsersIcon';
import PresentationScreenIcon from './PresentationScreenIcon';
import ArrowsDirectionsIcon from './ArrowsDirectionsIcon';
import EnvelopeIcon from './EnvelopeIcon';
import KnowledgeHubIcon from './KnowledgeHubIcon';
import HouseIcon from './HouseIcon';
import DownloadIcon from './DownloadIcon';
import PagerIcon from './PagerIcon';

const icons = {
  add: AddIcon,
  campaign: CampaignIcon,
  coach: CoachIcon,
  coinsConvertion: CoinsConvertionIcon,
  dashboard: DashboardIcon,
  email: EmailIcon,
  filters: FiltersIcon,
  lamp: LampIcon,
  logout: LogoutIcon,
  notes: NotesIcon,
  orderKickboxes: OrderKickboxesIcon,
  people: PeopleIcon,
  project: ProjectIcon,
  service: ServiceIcon,
  settings: SettingsIcon,
  unit: UnitIcon,
  innovationShop: InnovationIcon,
  onboardingRoom: OnboardingRoomIcon,
  transformation: Transformation,
  coaching: Coaching,
  employeeActivation: EmployeeActivation,
  programSuccess: ProgramSuccess,
  help: Help,
  helpInfo: HelpInfo,
  helpRequest: HelpRequest,
  helpBug: HelpBug,
  coachingHub: CoachingHub,
  comments: Comments,
  arrowIcon: ArrowIcon,
  booksIcon: BooksIcon,
  groupArrowsIcon: GroupArrowsIcon,
  personSignIcon: PersonSignIcon,
  dumbbelIcon: DumbbelIcon,
  mugHotIcon: MugHotIcon,
  usersIcon: UsersIcon,
  presentationScreenIcon: PresentationScreenIcon,
  arrowsDirectionsIcon: ArrowsDirectionsIcon,
  envelopeIcon: EnvelopeIcon,
  knowledgeHubIcon: KnowledgeHubIcon,
  houseIcon: HouseIcon,
  downloadIcon: DownloadIcon,
  pagerIcon: PagerIcon
};

export default {
  ...Object.keys(icons).reduce((components, key) => ({
    ...components,
    [key]: {
      component: icons[key]
    }
  }), {})
};
