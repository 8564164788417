import axios from 'axios';
import xhr from 'xhr';
import Parse from '../parse';

function uploadFilePart(url, file, bufferStart, bufferEnd) {
  return new Promise((resolve, reject) => {
    xhr.put({
      url,
      body: file.slice(bufferStart, bufferEnd)
    }, (err, resp) => {
      if (err) {
        reject(err);
      } else {
        const etag = resp.headers.etag.replace(/"/g, '');
        resolve(etag);
      }
    });
  });
}

function processFilePart(
  key,
  uploadId,
  partNumber,
  file,
  bufferStart,
  bufferEnd
) {
  return Parse.Cloud.run('signFileUrl', {
    key,
    uploadId,
    partNumber
  })
    .then((preSignedUrl) => uploadFilePart(
      preSignedUrl,
      file,
      bufferStart,
      bufferEnd
    ));
}

async function processUpload(file, key, acl, objectInfo) {
  const fileSize = file.size;
  const fileType = file.type;
  const fileKey = key || file.name;
  // ====================================================
  // 5 megabyte per part, we can change this later
  // ====================================================
  const chunkSize = 1024 * 1024 * 5;
  const numOfParts = Math.ceil(fileSize / chunkSize);
  const segments = Array(numOfParts).fill(null);

  const { uploadId } = await Parse.Cloud.run('startFileUpload', {
    key: fileKey,
    contentType: fileType,
    acl: acl || 'private',
    objectInfo
  });

  let start = 0;
  let chunkLeft = fileSize;

  const parts = await axios.all(segments.map(async (segment, index) => {
    const partNumber = index + 1;
    const chunkEnd = start + (chunkLeft > chunkSize ? chunkSize : chunkLeft);

    const uploadedPart = processFilePart(
      key,
      uploadId,
      partNumber,
      file,
      start,
      chunkEnd
    );

    chunkLeft -= chunkEnd - start + 1;
    start = chunkEnd + 1;
    return uploadedPart;
  }));
  const { success, location } = await Parse.Cloud.run('completeFileUpload', {
    key: fileKey,
    uploadId,
    parts,
    objectInfo
  });

  if (success) {
    return decodeURIComponent(location.replace(/http:\/\//g, 'https://'));
  }
  return false;
}

export async function uploadFile({ file, key, acl, objectInfo }) {
  return new Promise((resolve) => resolve(processUpload(file, key, acl, objectInfo)));
}

export function stopResourceUpload(key, uploadId) {
  return Parse.Cloud.run('stopResourceUpload', {
    key,
    uploadId
  });
}
