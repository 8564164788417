<template>
  <div>
    <confirm-dialog
      :confirm-click="deleteUnit"
      :show="showDialogDelete"
      @close="closeDeleteUnitDialog"
    />

    <template v-if="isLoading.units">
      <v-progress-linear
        :indeterminate="isLoading.units"
        height="2"
      />
      <h3>Loading units...</h3>
    </template>

    <template v-else>
      <v-card class="mb-4 pa-4 pt-6">
        <slot />

        <v-text-field
          v-model="newUnitName"
          v-ruid="'units'"
          clearable
          hint="Press enter to add"
          label="Unit*"
          placeholder="Add a new unit by name"
          required
          @keypress.enter="addUnit"
        />
      </v-card>

      <edit-unit
        v-for="(unit, index) in units"
        :key="unit.id"
        v-model="unit.newValue"
        :admins="companyAdminsAndUnitAdmins"
        :can-edit-admins="isMultiEntityEnabled"
        :unit="unit"
        @remove="openDeleteUnitDialog($event, index)"
      />

      <div>
        <app-button
          v-ruid="'saveUnit'"
          :loading="loadingButton"
          class="mt-4"
          @click="saveUnits"
        >
          Save
        </app-button>
      </div>
      <div class="mt-4">
        <label class="body-1">* Mandatory field</label>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FEATURE_NAMES from '@kickbox/common-util/constants/feature-names';
import { instance } from '@kickbox/common-util/src/util/parse';
import CompanyService from '../services/companyService';
import EditUnit from './EditUnit';
import ConfirmDialog from './ConfirmDialog';

export default {
  components: {
    EditUnit,
    ConfirmDialog
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newUnitName: '',
      units: [],
      companyAdmins: [],
      unitAdmins: [],
      loadingButton: false,
      showDialogDelete: false,
      deleteItem: null
    };
  },
  computed: {
    ...mapGetters([
      'isLoading'
    ]),
    isMultiEntityEnabled() {
      return Array.isArray(this.company.features)
        ? this.company.features.includes(FEATURE_NAMES.MULTI_ENTITY)
        : this.company.features[FEATURE_NAMES.MULTI_ENTITY];
    },
    companyAdminsAndUnitAdmins() {
      const companyAdminsAndUnitAdmins = [];
      if (this.companyAdmins) {
        companyAdminsAndUnitAdmins.push(...this.companyAdmins);
      }
      if (this.unitAdmins) {
        companyAdminsAndUnitAdmins.push(...this.unitAdmins);
      }
      return companyAdminsAndUnitAdmins;
    }
  },
  watch: {
    company() {
      this.companyAdmins = this.company.admins;
      this.unitAdmins = this.company.unitAdmins;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.$store.dispatch('setIsLoading', { units: true });
      this.units = await CompanyService.getUnitsByCompany(this.company.parseObject) || [];
      this.companyAdmins = this.company.admins;
      this.unitAdmins = this.company.unitAdmins;
      this.$store.dispatch('setIsLoading', { units: false });
    },
    duplicatesUnits() {
      const editedUnitsNames = this.units.filter((u) => u.newValue).map((u) => u.newValue.name);
      const unitsNames = this.units.filter((u) => !u.newValue).map((u) => u.name);
      const checkNames = editedUnitsNames.concat(unitsNames);
      return new Set(checkNames).size !== checkNames.length;
    },
    addUnit() {
      if (this.newUnitName) {
        this.units.unshift({
          name: this.newUnitName,
          newValue: {
            name: this.newUnitName,
            admins: [],
            active: true
          }
        });
        this.newUnitName = '';
      }
    },
    openDeleteUnitDialog(unit, index) {
      if (this.units.length > 1) {
        this.deleteItem = { unit, index };
        this.showDialogDelete = true;
      } else {
        this.$store.dispatch('showSnackBar', { text: 'There must be at least one unit' });
      }
    },
    closeDeleteUnitDialog() {
      this.deleteItem = null;
      this.showDialogDelete = false;
    },
    removeUnitItem() {
      this.units.splice(this.deleteItem.index, 1);
      this.$store.dispatch('showSnackBar', { text: `The "${this.deleteItem.unit.name}" unit was deleted successfully.` });
    },
    async deleteUnit() {
      this.showDialogDelete = false;
      if (this.deleteItem.unit && this.deleteItem.unit.id) {
        const isUsed = await CompanyService.isUnitUsed(this.deleteItem.unit.id);
        if (!isUsed) {
          this.removeUnitItem();
          await CompanyService.deleteUnit(this.deleteItem.unit.id);
          return this.$store.commit('setUnits', await CompanyService
            .getUnitsByCompany(instance('Company', { id: this.company.id })));
        }
        return this.$store.dispatch('showSnackBar', { text: 'This unit is in use by a project or/and user' });
      }
      this.removeUnitItem();
      this.deleteItem = null;
      return true;
    },
    someNameMissing() {
      return this.units.some((unit) => unit.newValue && !unit.newValue.name);
    },
    async saveUnits($event) {
      if (this.duplicatesUnits()) {
        this.$store.dispatch('showSnackBar', { text: 'Sorry, one or more units are duplicated.' });
        return;
      }
      if (this.someNameMissing()) {
        this.$store.dispatch('showSnackBar', { text: 'Sorry, some fields have been entered incorrectly.' });
      } else {
        this.loadingButton = true;
        const savedUnits = await Promise.all(Object.values(this.units)
          .filter((unit) => unit.newValue)
          .map((unit) => CompanyService.saveUnit(unit.newValue, this.company.parseObject)));
        this.units = this.units.map((unit) => {
          const exists = savedUnits.find((u) => unit.newValue && u.name === unit.newValue.name);
          return exists || unit;
        });
        this.$store.commit('setUnits', await CompanyService.getUnitsByCompany(this.company.parseObject), this.company.slug);
        this.$store.dispatch('showSnackBar', { text: 'The units have been saved.' });
        this.initialize();
        this.$emit('saved', $event);
        this.loadingButton = false;
      }
    }
  }
};
</script>
