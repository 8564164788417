<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="itemSelected"
          :items="items"
          placeholder="Select"
          item-value="key"
          item-text="name"
          single-line
          required
        />
      </v-col>
    </v-row>
    <label
      v-if="itemSelected && itemsMap.get(itemSelected).description"
      class="content-label"
    >
      {{ itemsMap.get(itemSelected).description }}
    </label>

    <select-users
      v-if="itemSelected == DEFAULT_CONTENT.PAGES.WELCOME_MESSAGE.key && showWelcomeAdmin"
      v-model="welcomeMessageAdmin"
      :source="admins"
      :multiple="false"
      label="Welcome-Message Admin (latest Admin added to the company, if none is selected)"
    />

    <v-radio-group
      v-if="showDefaultCustomOptions && itemSelected && !addedTemplate"
      v-model="defaultCustomOption"
    >
      <v-radio
        :label="defaultOptionLabel"
        value="default"
        color="primary"
      />
      <v-radio
        :label="customOptionLabel"
        value="custom"
        color="primary"
      />
    </v-radio-group>

    <v-tabs
      v-if="itemSelected"
      v-model="active"
      background-color="primary"
      slider-color="white"
      dark
      class="tabs"
    >
      <v-tab
        v-for="language in availableLanguages"
        :key="language"
        v-ripple
      >
        {{ language }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="active">
      <v-tab-item
        v-for="(content, i) in getActiveArray"
        :key="i"
      >
        <v-text-field
          v-if="showSubject"
          ref="subject"
          v-model="content.subject"
          :disabled="editorContentDisabled"
          label="Subject"
          clearable
          placeholder="Enter a subject"
          class="mt-6"
          required
          @input="(subject) => updateContent({subject}, i)"
        />
        <editor
          :content="content.text"
          :submit-button-disabled="submitButtonDisabled"
          :disabled="editorContentDisabled"
          :submit-button-text="saveContent.text"
          :editor-variable-slot="editorVariableSlot"
          :toolbar="itemsMap.get(itemSelected).toolbar || 'contentToolbar'"
          @keyUp="(text) => updateContent({text}, i)"
          @submit="save()"
        />
        <label
          v-if="saveContent.description"
          class="content-label"
        >{{ saveContent.description }}
        </label>

        <v-row
          v-if="showSendPreview"
          class="mt-6 email-preview-container"
        >
          <v-col cols="7">
            <v-text-field
              v-if="showSendPreview"
              v-model="emailPreview"
              :disabled="sendPreviewDisabled(i)"
              label="Email Preview"
              clearable
              placeholder="Enter an email"
              class="mt-4"
              hint="Email needs to be already registered in the platform"
              persistent-hint
            />
          </v-col>
          <v-col>
            <app-button
              :disabled="sendPreviewDisabled(i)"
              class="my-4"
              @click="sendEmailsPreview(i)"
            >
              Send
            </app-button>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { formValidation } from '@kickbox/common-util';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';
import SelectUsers from './SelectUsers';
import UserService from '../services/userService';
import LanguageService from '../services/languageService';
import Editor from './Editor';
import companyService from '../services/companyService';

export default {
  name: 'ContentTemplate',
  components: {
    Editor,
    SelectUsers
  },
  props: {
    showWelcomeAdmin: {
      type: Boolean,
      default: false
    },
    admins: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    defaultArray: {
      type: Array,
      default: () => []
    },
    customArray: {
      type: Array,
      default: () => []
    },
    enabledLanguages: {
      type: Array,
      default: () => []
    },
    showSubject: {
      type: Boolean,
      default: false
    },
    showDefaultCustomOptions: {
      type: Boolean,
      default: false
    },
    showSendPreview: {
      type: Boolean,
      default: false
    },
    editorVariableSlot: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentUser: UserService.getCurrentUser(),
      itemSelected: '',
      active: 0,
      availableLanguages: [],
      defaultCustomOption: 'default',
      emailPreview: '',
      activeCustomOption: false,
      welcomeMessageAdmin: null,
    };
  },
  computed: {
    DEFAULT_CONTENT() { return Object.freeze(DEFAULT_CONTENT); },
    isDefault() {
      return this.defaultCustomOption === 'default' || !this.showDefaultCustomOptions;
    },
    getActiveArray() {
      if (this.isDefault && this.defaultArray) {
        return this.mapOrderBaseOnLanguage(this.getItemSelectedDefaultArray);
      }
      if (!this.isDefault && this.customArray) {
        return this.mapOrderBaseOnLanguage(this.getItemSelectedCustomArray);
      }
      return [];
    },
    getItemSelectedDefaultArray() {
      return this.defaultArray.filter(this.filterSelectedItem);
    },
    getItemSelectedCustomArray() {
      return this.customArray.filter(this.filterSelectedItem);
    },
    editorContentDisabled() {
      return this.defaultCustomOption === 'default' && this.showDefaultCustomOptions;
    },
    saveContent() {
      if (this.isDefault && this.showDefaultCustomOptions) {
        return {
          text: 'save',
          description: ''
        };
      }
      if (!this.isDefault && this.showDefaultCustomOptions) {
        return {
          text: 'save all*',
          description: '*All the languages are required, a default language content will be save as custom if you don\'t provide one.',
        };
      }
      return {
        text: 'save*',
        description: '*All the languages are required.'
      };
    },
    itemsMap() {
      return new Map(this.items.map((item) => [item.key, item]));
    },
    submitButtonDisabled() {
      return this.getActiveArray
        .some((item) => !!(this.showSubject && !item.subject) || !item.text);
    },
    addedTemplate() {
      return this.items.some((i) => i.key === this.itemSelected && i.addedTemplate);
    },
    defaultOptionLabel() {
      return !this.activeCustomOption ? 'Use default content (currently active)' : 'Use default content';
    },
    customOptionLabel() {
      return this.activeCustomOption ? 'Use custom content (currently active)' : 'Use custom content';
    }
  },
  watch: {
    itemSelected() {
      this.active = 0;
      if (this.customArray.length) {
        const customEnabled = this.customArray
          .find((content) => content.customEnabled && this.filterSelectedItem(content));
        this.defaultCustomOption = customEnabled ? 'custom' : 'default';
        this.activeCustomOption = !!customEnabled;
      }
      this.addAllLanguagesToContent();
    }
  },
  async created() {
    if (this.showWelcomeAdmin) {
      const welcomeAdminMail = this.$store.getters.company.parseObject.get('welcomeMessageAdmin');
      [this.welcomeMessageAdmin] = await UserService.fetchUsersByEmail(welcomeAdminMail);
    }

    if (this.currentUser) {
      this.emailPreview = this.currentUser.get('username');
    }
    const enabledLanguages = !this.showDefaultCustomOptions ? false : this.enabledLanguages;
    const languages = await LanguageService.getLanguages(enabledLanguages);
    this.availableLanguages = languages.map((lang) => lang.key);
  },
  methods: {
    mapOrderBaseOnLanguage(array) {
      array.sort((a, b) => {
        if (this.availableLanguages.indexOf(a.language)
          > this.availableLanguages.indexOf(b.language)) {
          return 1;
        }
        if (this.availableLanguages.indexOf(a.language)
          < this.availableLanguages.indexOf(b.language)) {
          return -1;
        }
        return 0;
      });
      return array;
    },

    addAllLanguagesToContent() {
      if (this.isDefault && !this.showDefaultCustomOptions) {
        const defaultContentArray = this.availableLanguages.reduce((content, language) => {
          const defaultLanguage = this.getItemSelectedDefaultArray.find(
            (defaultContent) => defaultContent.language === language
          );
          content.push(defaultLanguage || {
            default: true,
            language,
            text: '',
            name: this.itemSelected,
          });
          return content;
        }, []);
        this.$emit('on-update-contents', defaultContentArray);
      }
      if (this.showDefaultCustomOptions) {
        const defaultArray = this.getItemSelectedDefaultArray;
        const customContentArray = this.availableLanguages.reduce((content, language) => {
          const customLanguage = this.getItemSelectedCustomArray.find(
            (custom) => custom.language === language
          );
          const currentContent = defaultArray
            .find((defaultContent) => defaultContent.language === language) || {};
          content.push(customLanguage || {
            default: false,
            language,
            text: currentContent.text,
            name: this.itemSelected,
            subject: currentContent.subject,
          });
          return content;
        }, []);
        this.$emit('on-update-contents', customContentArray);
      }
    },
    filterSelectedItem(content) {
      return content.name === this.itemSelected
        && this.availableLanguages.includes(content.language);
    },
    sendPreviewDisabled(i) {
      return !this.getActiveArray[i].text || !this.getActiveArray[i].subject;
    },
    save() {
      if (this.showWelcomeAdmin) {
        if (!this.welcomeMessageAdmin) {
          companyService.updateWelcomeMessageAdmin('');
        } else {
          companyService.updateWelcomeMessageAdmin(this.welcomeMessageAdmin.username);
        }
      }
      const customOptionSelected = this.defaultCustomOption === 'custom';
      this.activeCustomOption = customOptionSelected;
      const contentArray = this.showDefaultCustomOptions
        ? this.getItemSelectedCustomArray : this.getItemSelectedDefaultArray;
      const propertiesArray = contentArray.map((content) => {
        let properties = {
          name: this.itemSelected,
          text: content.text,
          language: content.language,
          default: content.default,
        };

        if (customOptionSelected) {
          properties.default = false;
        }

        if (this.showDefaultCustomOptions) {
          properties = Object.assign(properties, {
            customEnabled: customOptionSelected,
            company: this.$store.getters.company.parseObject
          });
        }

        if (content.subject) {
          properties = Object.assign(properties, { subject: content.subject });
        }
        return properties;
      });
      return this.$emit('on-save-content', propertiesArray, contentArray);
    },
    sendEmailsPreview(i) {
      if (!formValidation.emailIsValid(this.emailPreview)) {
        return this.$store.dispatch('showSnackBar', { text: 'Enter a valid email address.' });
      }
      const companyId = this.$store.getters.company.id;
      return this.$emit('on-send-preview-email', {
        email: this.emailPreview,
        companyId,
        content: { text: this.getActiveArray[i].text, subject: this.getActiveArray[i].subject }
      });
    },
    updateContent(property, i) {
      const content = this.getActiveArray[i];
      if (content.default && this.showDefaultCustomOptions) {
        return {};
      }
      const updatedProperty = Object.assign(this.getActiveArray[i], property, { isSaved: false });
      return this.$emit('on-update-content', updatedProperty);
    }
  }
};
</script>

<style>
  .tabs {
    max-width: 100vw;
  }

  .email-preview-container .input-group__input {
    margin-top: 10px;
  }

  .content-label {
    margin-bottom: 10px;
    display: inline-block;
    color: #0000008a;
  }

</style>
