<template>
  <v-list
    class="admin-display-container"
    color="transparent"
  >
    <v-list-item>
      <v-list-item-content class="text-right">
        <v-list-item-title class="font-weight-medium body-2">
          {{ name }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption font-weight-medium grey--text">
          {{ displayedRole }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-img :src="photo" />
      </v-list-item-avatar>
    </v-list-item>
  </v-list>
</template>

<script>
import { adminTypeToString } from '@kickbox/common-util/src/util/adminTypeToString';
import noPhotoIcon from '../assets/img/no-avatar-icon.svg';

export default {
  name: 'AdminDisplay',
  props: ['userRole', 'currentUser'],
  data() {
    return {
      user: null,
      photo: '',
      name: '',
      displayedRole: '',
    };
  },
  mounted() {
    this.name = this.currentUser.get('name');
    this.photo = this.currentUser.get('photo') || noPhotoIcon;
    if (this.userRole) {
      this.displayedRole = adminTypeToString(this.userRole);
    } else {
      this.displayedRole = 'Meta Admin';
    }
  },
};
</script>

<style lang="scss">
  .admin-display-container {
    .body-2 {
      font-weight: 600 !important;
    }
  }
</style>
