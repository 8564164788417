<template>
  <div>
    <vue-editor
      ref="editorBase"
      :key="toolbarKey"
      v-model="editorContent"
      :editor-toolbar="editorToolbar"
      :disabled="disabled"
      :class="{ 'disabled-toolbar': disabled, 'validation-hint': showValidationHint}"
      class="editor"
    />
    <slot />
    <editor-variable-slot
      :variables="editorVariableSlot"
    />
    <app-button
      v-if="showCancelButton"
      v-ruid="'cancelButton'"
      secondary
      cancel
      class="my-6 mr-4"
      @click="cancel"
    >
      {{ cancelButtonText }}
    </app-button>
    <app-button
      v-if="showSubmitButton"
      v-ruid="'submitButton'"
      :disabled="editorSubmitButtonDisabled"
      class="my-6"
      color="primary"
      @click="submit"
    >
      {{ submitButtonText }}
    </app-button>
    <div
      v-if="showValidationHint"
      class="v-messages theme--light error--text"
    >
      The field is required
    </div>
  </div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import { customLinkSanitizer } from '@kickbox/common-util';
import EditorVariableSlot from './EditorVariableSlot';

customLinkSanitizer(Quill);

export default {
  components: {
    VueEditor,
    EditorVariableSlot
  },
  props: {
    /**
     * Vue editor with cancel/save buttons.
     * @constructor
     * @param {string} content - Content of the editor.
     * @param {boolean} disabled - Disabled the content editor, by default false.
     * @param {string} toolbar - Toolbar of the editor, by default use ${defaultEditorToolbar}.
     * @param {string} submitButtonText - Submit button text, by default 'Save'.
     * @param {boolean} submitButtonDisabled - Disabled submit button, by default false.
     * @param {string} cancelButtonText - Cancel button text, by default 'Cancel'.
     * @param {boolean} showSubmitButton - Show cancel button, by default true.
     * @param {boolean} showCancelButton - Show cancel button, by default false.
     */
    content: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    toolbar: {
      required: false,
      default: 'defaultEditorToolbar'
    },
    submitButtonText: {
      type: String,
      required: false,
      default: 'Save'
    },
    submitButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    showSubmitButton: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    editorVariableSlot: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultEditorToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ header: 1 }, { header: 2 }], // custom button values
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        [{ color: [] }], // dropdown with defaults from theme,
        [{ align: [] }],
      ],
      contentEditorToolbar: [
        [{ header: [3, 4, 5, false] }],
        ['bold'], // toggled buttons
        [{ list: 'bullet' }],
        ['link']
      ],
      minimalEditorToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ list: 'bullet' }],
        ['link']
      ],
      linkToolbar: [
        ['link']
      ],
      toolbarKey: 0,
      editorContent: this.$sanitize(this.content),
      editorToolbar: this.defaultEditorToolbar,
      showValidationHint: false
    };
  },
  computed: {
    editorSubmitButtonDisabled() {
      return this.submitButtonDisabled || !this.editorContent;
    }
  },
  watch: {
    content() {
      this.editorContent = this.$sanitize(this.content);
    },
    editorContent() {
      this.$emit('keyUp', this.editorContent);
    },
    toolbar: {
      immediate: true,
      handler() {
        this.editorToolbar = this.defaultEditorToolbar;
        if (this.toolbar === 'contentToolbar') {
          this.editorToolbar = this.contentEditorToolbar;
        } else if (this.toolbar === 'minimalToolbar') {
          this.editorToolbar = this.minimalEditorToolbar;
        } else if (this.toolbar === 'linkToolbar') {
          this.editorToolbar = this.linkToolbar;
        } else if (this.toolbar && typeof this.toolbar !== 'string') {
          this.editorToolbar = this.toolbar;
        }
        // Used to change the toolbar by re-render the editor
        this.toolbarKey += 1;
      }
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.editorContent);
    },
    cancel() {
      this.$emit('cancel', false);
    },
    clearContent() {
      this.editorContent = '';
    },
    validate() {
      const isValid = !this.required || (this.editorContent && this.editorContent.length);
      this.showValidationHint = !isValid;
      return isValid;
    }
  }
};
</script>

<style scoped lang="scss">
  .listFormat {
    cursor: default;
    padding-bottom: 5px;
  }

  .listFormat ul,
  .listFormat ol {
    padding-left: 20px;
  }

  .listFormat ul {
    list-style-type: disc;
  }

  .listFormat li, .ql-editor li {
    border: none;
  }

  .listFormat p {
    margin: 0;
  }

  .ql-container {
    height: 250px !important;
  }

  .ql-disabled {
    color: gray;
  }

  .disabled-toolbar .ql-toolbar {
    display: none;
  }

  .validation-hint {
    border: 1px solid #ff5252;
  }

  .editor {
    border-radius: 4px;
    overflow: hidden;
  }
</style>

<style lang="scss">
  .ql-editor {
    ul {
      padding-left: 0 !important;
    }
  }
  .disabled-toolbar {
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      border-top: 1px solid #ccc !important;
    }
  }
</style>
