<template>
  <v-dialog
    :value="show"
    max-width="400"
    persistent
  >
    <v-card class="pa-2">
      <v-card-title class="title">
        <slot>Are you sure?</slot>
      </v-card-title>

      <v-card-actions>
        <v-spacer />

        <app-button
          cancel
          secondary
          @click="close"
        >
          Cancel
        </app-button>

        <app-button
          class="ml-4"
          :disabled="disabled"
          @click="confirmClick"
        >
          {{ confirmLabel }}
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    confirmClick: {
      type: Function,
      default: null
    },
    confirmLabel: {
      type: String,
      default: 'Confirm'
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode === 27) { // Escape key
        this.close();
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
  .title {
    line-height: 2rem!important;
    word-break: normal;
  }
</style>
