<template>
  <v-btn
    :loading="loading"
    :disabled="loading || disabled"
    :color="buttonColor"
    :block="buttonBlock"
    :text="buttonFlat"
    class="white--text"
    @click="buttonClick"
  >
    {{ buttonText }}
    <span
      slot="loader"
      class="custom-loader"
    >
      <v-icon light>
        cached
      </v-icon>
    </span>
  </v-btn>
</template>

<script>
export default {
  props: ['buttonText', 'loading', 'color', 'text', 'disabled', 'small'],
  data() {
    return {
      buttonColor: 'primary',
      buttonBlock: true,
      buttonFlat: false,
    };
  },
  created() {
    if (this.color) {
      this.buttonColor = this.color;
    }
    if (this.text) {
      this.buttonBlock = false;
      this.buttonFlat = true;
    }
    if (this.small) {
      this.buttonBlock = false;
    }
  },
  methods: {
    buttonClick($event) {
      this.$emit('btnClicked', $event);
    }
  }
};
</script>

<style scoped>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
