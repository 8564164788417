<template>
  <v-row>
    <v-col cols="12">
      <v-card class="content-card-padding">
        <v-container fluid>
          <v-card-title class="mb-6 px-0">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-text-field
                  v-model="pagination.search"
                  label="Search"
                  append-icon="search"
                  single-line
                  hide-details
                  clearable
                  v-on="on"
                />
              </template>
              <span>{{ searchLabel }}</span>
            </v-tooltip>
            <v-spacer />
            <app-button @click="buttonClick()">
              {{ buttonText }}
            </app-button>
          </v-card-title>
          <v-progress-linear
            v-if="loader"
            :indeterminate="loader"
            height="2"
            class="mb-6"
          />
          <v-data-iterator
            :items="companies"
            :search="pagination.search"
            :custom-filter="customFilter"
            :no-data-text="noData"
            :options.sync="pagination"
            :items-per-page="12"
            :footer-props="{
              itemsPerPageOptions: pagination.rowsPerPageItems
            }"
            row
            wrap
          >
            <template #default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="3"
                >
                  <v-card
                    :to="`${editPath}/${item.slug || item.id}`"
                    hover
                    class="mb-4 card"
                    max-width="300"
                  >
                    <v-img
                      :src="getUserImage(item.logo)"
                      :class="'card-logo'"
                      contain
                    />
                    <v-tooltip
                      v-if="item.domain"
                      bottom
                    >
                      <template #activator="{ on }">
                        <v-card-title
                          v-ruid="`domain@${item.domain[0]}`"
                          class="text-center d-block grey--text text--darken-2 py-0 ellipsis body-1"
                          v-on="on"
                        >
                          @{{ item.domain[0] }}
                        </v-card-title>
                      </template>
                      <!-- eslint-disable vue/no-v-html -->
                      <span v-html="$sanitize(formatHtmlDomain(item.domain))" />
                      <!-- eslint-enable vue/no-v-html -->
                    </v-tooltip>
                    <v-card-title
                      v-if="!item.domain"
                      class="text-center d-block pt-4 ellipsis subtitle-2"
                    >
                      {{ item.name }}
                    </v-card-title>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-card-title
                          v-if="item.email"
                          class=" text-center grey--text text--darken-2 py-0 mb-2 ellipsis body-2"
                          v-on="on"
                        >
                          {{ formatItemEmail(item.email) }}
                        </v-card-title>
                      </template>
                      <span>{{ formatItemEmail(item.email) }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import placeholderAvatar from '../assets/img/no-avatar-icon.svg';

export default {
  props: ['companies', 'type'],
  data() {
    return {
      pagination: {
        rowsPerPage: 12,
        rowsPerPageItems: [12, 24, 36, { text: 'All', value: -1 }],
        search: '',
      },
      searchLabel: '',
      editPath: '',
    };
  },
  url: {
    pagination: {
      param: 'pagination',
      noHistory: true
    },
  },
  computed: {
    loader() {
      const { companies, serviceProviders } = this.$store.getters.isLoading;
      return serviceProviders || companies;
    },
    noData() {
      let noData = 'loading...';
      if (!this.loader) {
        noData = this.type === 'company'
          ? 'There are no companies created.' : 'There are no service providers added';
      }
      return noData;
    },
  },
  created() {
    if (this.type === 'company') {
      this.buttonText = 'Add a company';
      this.editPath = 'edit';
      this.searchLabel = 'Company name';
    } else {
      this.buttonText = 'Invite service provider';
      this.editPath = 'service-providers/edit-provider';
      this.searchLabel = 'Company name or email';
    }
  },
  methods: {
    customFilter(items, search) {
      const customSearch = search ? search.toString().toLowerCase() : '';
      if (this.type === 'provider') {
        return items.filter((row) => row.name.toLowerCase().includes(customSearch)
          || row.email.toLowerCase().includes(customSearch));
      }
      return items.filter((row) => row.name.toLowerCase().includes(customSearch));
    },
    buttonClick() {
      this.$emit('buttonClick');
    },
    formatHtmlDomain(domains) {
      return domains.join('<br>');
    },
    getUserImage(image) {
      if (image) {
        return image;
      }
      return placeholderAvatar;
    },
    formatItemEmail(email) {
      return email && email.includes('deleted@kickbox.com') ? 'Deleted User' : email;
    }
  },
};
</script>

<style scoped>
  .card {
    min-height: 200px !important;
    width: 100% !important;
    padding: 5px;
  }
  .card-logo {
    height: 120px !important;
    width: 100% !important;
  }
  .ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input-group {
    min-width: 172px;
  }
</style>
