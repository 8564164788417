<template>
  <v-dialog
    :value="showDialog"
    persistent
    max-width="400"
  >
    <v-card class="tag-container">
      <v-card-title>
        <span class="subtitle-1">
          Tag Details
        </span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.trim="name"
          :counter="tagCharLimit"
          :rules="rules"
          label="Name"
          placeholder="Enter a tag name"
          autofocus
          required
        />
      </v-card-text>
      <v-checkbox
        v-model="disabled"
        :disabled="preventDisable"
        label="Disabled"
        color="primary"
        class="check-box ml-4"
        input-value="true"
      />
      <v-card-actions>
        <v-spacer />
        <app-button
          secondary
          cancel
          @click.native="closeDialog"
        >
          Cancel
        </app-button>
        <app-button
          class="ml-4"
          @click.native="save"
        >
          save
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    preventDisable: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    },
    tag: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      disabled: false,
      tagCharLimit: 30,
      name: '',
      rules: [(v) => v.length <= this.tagCharLimit || `Max ${this.tagCharLimit} characters`],
    };
  },
  computed: {
    nameEditModeDidNotChange() {
      return this.tag && this.tag.name === this.name;
    }
  },
  created() {
    if (this.tag) {
      this.name = this.tag.name;
      this.disabled = this.tag.disabled;
    }
  },
  methods: {
    closeDialog() {
      this.cleanProperties();
      this.$emit('close-dialog');
    },
    save() {
      if (this.isValidTag()) {
        const propertiesToSave = {
          id: this.tag?.id,
          name: this.name,
          disabled: this.disabled
        };
        this.$emit('save', propertiesToSave);
      }
    },
    isValidTag() {
      if (this.name.length > this.tagCharLimit) {
        this.$store.dispatch('showSnackBar', { text: `Enter a tag name with a max of ${this.tagCharLimit} characters.` });
        return false;
      }
      if (!this.name) {
        this.$store.dispatch('showSnackBar', { text: 'Enter a tag name.' });
        return false;
      }
      const tagNameExists = this.tags.some(
        (t) => t.name.toLowerCase() === this.name.toLowerCase()
      );
      if (tagNameExists && !this.nameEditModeDidNotChange) {
        this.$store.dispatch('showSnackBar', { text: 'The tag name already exists.' });
        return false;
      }
      return true;
    },
    cleanProperties() {
      this.name = '';
      this.disabled = false;
    }
  }
};
</script>
