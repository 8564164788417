<template>
  <app-button
    :loading="isLoading"
    :disabled="disabled"
    class="white--text"
    secondary
    @click="exportExcel"
  >
    Export {{ name }}
  </app-button>
</template>

<script>
import XLSX from 'xlsx';
import moment from 'moment';
import { exportSanitizeOptions } from '@kickbox/common-util';

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    exportData: {
      type: [Array, Object, Promise, Function],
      default: null
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    fileName: {
      type: String,
      default: 'export'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    resolveObjectPath(path, obj) {
      const properties = Array.isArray(path) ? path : path.split('.');
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
    formatValue(path, value) {
      let returnValue = this.resolveObjectPath(path, value);
      if (moment.isMoment(returnValue)) {
        returnValue = returnValue.isValid() ? returnValue.format('DD.MM.YYYY') : '';
      }
      if (returnValue instanceof Date) {
        returnValue = moment(returnValue).isValid() ? moment(returnValue).format('DD.MM.YYYY') : '';
      }
      if (Array.isArray(returnValue)) {
        returnValue = returnValue.join(',');
      }
      const sanitizeValue = this.$sanitize(returnValue, exportSanitizeOptions);
      return returnValue ? String(sanitizeValue) : '';
    },
    async exportExcel() {
      this.isLoading = true;
      let data;
      if (typeof this.exportData === 'function') {
        data = await this.exportData();
      } else {
        data = await this.exportData;
      }
      if (!data) {
        this.$store.dispatch('showSnackBar', { text: 'There is no data to export' });
        this.isLoading = false;
        return false;
      }

      let excelSheets = { ...data };
      let fields = { ...this.fields };
      if (Array.isArray(data)) {
        excelSheets = {
          [this.name]: data
        };
        fields = {
          [this.name]: this.fields
        };
      }
      const excel = XLSX.utils.book_new();

      Object.keys(fields).forEach((sheet) => {
        const values = excelSheets[sheet].map((value) => Object.keys(fields[sheet])
          .reduce((object, key) => ({
            ...object,
            [key]: this.formatValue(fields[sheet][key], value)
          }), {}));

        const excelSheet = XLSX.utils.json_to_sheet(values);
        XLSX.utils.book_append_sheet(excel, excelSheet, sheet);
      });
      this.isLoading = false;
      return XLSX.writeFile(excel, `${this.fileName}.xlsx`);
    }
  }
};
</script>
