import Parse from '@kickbox/common-util/src/parse';
import { instance, query } from '@kickbox/common-util/src/util/parse';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import fileService from '@kickbox/common-util/service/fileService';
import UserService from './userService';
import RoleService from './roleService';
import UnitService from './unitService';

export default {
  formatCompany(company) {
    const companyCoaches = company.get(ADMIN_ROLES.COACHES) || {};
    const internalCoaches = companyCoaches[ADMIN_ROLES.INTERNAL_COACHES] || [];
    const externalCoaches = companyCoaches[ADMIN_ROLES.EXTERNAL_COACHES] || [];
    const coaches = [...internalCoaches, ...externalCoaches];

    return {
      id: company.id,
      name: company.get('name'),
      slug: company.get('slug'),
      domain: company.get('domain'),
      ssoData: company.get('ssoData') || {},
      logo: company.get('logo'),
      enabled: company.get('enabled') == null ? false : company.get('enabled'),
      features: company.get('features') || [],
      campaigns: company.get('campaigns') == null ? [] : company.get('campaigns').map((ck) => ({
        id: ck.id,
        title: ck.get('title')
      })),
      projectAgreement: company.get('projectAgreement') == null ? '' : company.get('projectAgreement'),
      footer: company.get('footer') == null ? [] : company.get('footer'),
      enabledLanguages: company.get('enabledLanguages'),
      language: company.get('language'),
      parseObject: company,
      coaches,
      internalCoaches,
      externalCoaches,
      admins: company.get(ADMIN_ROLES.COMPANY_ADMINS) || [],
      unitAdmins: company.get(ADMIN_ROLES.UNIT_ADMINS) || [],
      airtableData: company.get('airtableData') || {},
      onboardingRoomData: company.get('onboardingRoomData') || {},
      knowledgeHubData: company.get('knowledgeHubData') || {},
      smartlook: company.get('smartlook') || false,
      trial: company.get('trial') || false,
      coachingLanguages: company.get('coachingLanguages') ? company.get('coachingLanguages') : [],
      migrated: company.get('migrated') || false,
      whiteEmailMigration: company.get('whiteEmailMigration') || '',
    };
  },
  getCompanyById(companyId) {
    return query('Company')
      .equalTo('objectId', companyId)
      .equalTo('enabled', true)
      .notEqualTo('archive', true)
      .include('campaigns')
      .first();
  },
  async getUnitsByCompany(company) {
    const units = await query('Unit')
      .equalTo('company', company)
      .ascending('name')
      .find();
    return units.map(UnitService.formatUnit);
  },
  getCompanyByName(companyName) {
    return query('Company')
      .equalTo('name', companyName)
      .equalTo('enabled', true)
      .notEqualTo('archive', true)
      .include('campaigns')
      .first();
  },
  allCompanies(onlyEnabled = false) {
    const companyQuery = query('Company');
    if (onlyEnabled) {
      companyQuery.equalTo('enabled', true);
    }
    companyQuery.include('campaigns');
    companyQuery.notEqualTo('archive', true);
    companyQuery.descending('createdAt');
    companyQuery.limit(10000);
    return companyQuery.find()
      .then((companies) => Promise.all(companies.map((company) => this.formatCompany(company))));
  },
  async createCompany(imageFile, formFields) {
    const companyInstance = instance('Company');
    const company = await companyInstance.save({ ...formFields });
    const objectInfo = {
      type: 'Company',
      id: company.id,
      field: 'logo'
    };
    const location = await fileService
      .uploadImage(imageFile, null, 'public-read', objectInfo);
    company.set('logo', location);
    return this.formatCompany(company);
  },
  isUnitUsed(unitId) {
    return Parse.Cloud.run('isUnitUsed', { unitId });
  },
  async saveUnit({ id, name, admins, active }, company) {
    const unitInstance = instance('Unit', { id });
    let unit;
    try {
      unit = await unitInstance.save({
        name, admins, active, company
      });
      return UnitService.formatUnit(unit);
    } catch (error) {
      return false;
    }
  },
  deleteUnit(id) {
    return instance('Unit', { id }).destroy();
  },
  getCompanyWhiteList(parseCompany) {
    return query('UserWhitelist')
      .limit(10000)
      .equalTo('company', parseCompany)
      .find();
  },
  addEmailUserToCompanyWhitelist(userEmail, parseCompany) {
    const whiteListinstance = instance('UserWhitelist');
    return whiteListinstance.save({
      email: userEmail, company: parseCompany
    });
  },
  async updateAdminUserEmails(emailsArray, company, adminUserType) {
    await UserService.createUsersIfDoesNotExists(
      emailsArray.add,
      company.id,
      adminUserType
      && [ADMIN_ROLES.COMPANY_ADMINS, ADMIN_ROLES.UNIT_ADMINS].includes(adminUserType)
    );
    await UserService.addCompanyIfUserDoesNotHaveOne(emailsArray.add, company);
    await Parse.Cloud.run('setCompanyAdminUserEmails', { adminUserType, emails: emailsArray.all, companyId: company.id });

    await RoleService.updateAdminsUsersRole(
      company.id,
      emailsArray.add,
      emailsArray.remove,
      adminUserType === ADMIN_ROLES.COMPANY_ADMINS
    );

    return true;
  },
  async removeAdminsFromUnits(removeEmails) {
    return Parse.Cloud.run('removeAdminsFromUnits', { emails: removeEmails });
  },
  async removeCoachesFromProjects(coachEmailsToRemove) {
    return Parse.Cloud.run('removeCoachesFromProjects', {
      coachEmailsToRemove
    });
  },
  async updateWelcomeMessageAdmin(admin) {
    return Parse.Cloud.run('setCompanyWelcomeMessage', { admin });
  }
};
