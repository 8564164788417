<template>
  <div>
    <label class="d-block grey--text mb-2 text--darken-1">
      {{ label }}
      {{ required ? '*' : '' }}
    </label>
    <croppa
      v-model="image"
      v-ruid="'image'"
      :placeholder="placeholder"
      :placeholder-font-size="placeholderFontSize"
      :placeholder-color="'default'"
      :file-size-limit="fileSizeLimit"
      :show-remove-button="showRemoveButton && !disabled"
      :remove-button-color="'#3f51b5'"
      :remove-button-size="20"
      :zoom-speed="7"
      :width="width"
      :height="height"
      accept=".svg,.png,.jpg,.jpeg,.gif"
      :disabled="disabled"
      canvas-color="white"
      @draw="imageMovedOrResized"
      @image-remove="imageRemoved"
      @file-size-exceed="fileSizeExceed"
    >
      <img
        v-if="initialImage"
        slot="initial"
        crossOrigin="anonymous"
        :src="`${initialImage}?t=${getRandomInt}`"
      >
    </croppa>
    <label
      v-if="!disabled"
      class="d-block"
    >
      {{ hint }}
    </label>
  </div>
</template>

<script>
import { getRandomInt } from '@kickbox/common-util';

export default {
  name: 'SelectImage',
  props: {
    initialImage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Choose or drag an image file'
    },
    placeholderFontSize: {
      type: Number,
      default: 14
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    fileSizeLimit: {
      type: Number,
      default: 5242880 // 5 MB
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Cover Image'
    },
  },
  data() {
    return {
      image: null,
      initialDraw: true
    };
  },
  computed: {
    getRandomInt() {
      return getRandomInt(1000);
    },
    hint() {
      return this.image && this.image.hasImage()
        ? 'Please adjust the image to fit the shape above (move and zoom)'
        : `Max upload size: ${this.maxSize} MB`;
    },
    maxSize() {
      return this.fileSizeLimit / (1024 * 1024);
    }
  },
  beforeDestroy() {
    this.image.remove();
  },
  methods: {
    imageMovedOrResized() {
      if (this.initialDraw) {
        this.initialDraw = false;
      } else {
        this.image.currentIsInitial = false;
        this.$emit('change', this.image);
      }
    },
    imageRemoved() {
      this.$emit('remove');
    },
    fileSizeExceed() {
      const file = this.image.getChosenFile();
      if (file && file.size > this.fileSizeLimit) {
        this.$store.dispatch('showSnackBar', {
          text: `The image max size is ${this.maxSize} MB`
        });
        setTimeout(() => {
          this.image.remove();
        }, 100);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .croppa-container {
    border: solid 1px #b3aeae;
  }
  .croppa--disabled.croppa--has-target {
    background-color: transparent;
  }
  label {
    font-size: 12px;
  }
</style>
