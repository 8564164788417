import Parse from '@kickbox/common-util/src/parse';
import { buildTagDTO } from '@kickbox/common-util';

export default {
  getProjectTagCount() {
    return Parse.Cloud.run('getProjectTagCount', { global: true });
  },
  getGlobalTags() {
    return Parse.Cloud.run('getTags', { global: true, disabled: true });
  },
  getCompanyTags() {
    return Parse.Cloud.run('getTags', { disabled: true });
  },
  updateTag(properties) {
    return Parse.Cloud.run('updateTag', properties);
  },
  removeTag(id) {
    return Parse.Cloud.run('removeTag', { id });
  },
  buildTagDTO
};
