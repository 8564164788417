<template>
  <v-row
    class="contact-container mb-2"
    dense
  >
    <v-col
      cols="4"
    >
      <v-select
        :disabled="readOnly"
        :items="selectOptions"
        :value="contact.type"
        single-line
        label="Select"
        @input="update('type', $event)"
      />
    </v-col>
    <v-col cols="7">
      <v-text-field
        :clearable="!readOnly"
        :disabled="readOnly"
        :value="contact.value"
        @input="update('value', $event)"
      />
    </v-col>
    <v-col
      :class="{ hidden: readOnly }"
      cols="1"
      align-self="center"
    >
      <v-btn
        rounded
        icon
        @click="removeContact()"
      >
        <v-icon color="grey darken-1">
          delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ContactInfo',
  props: {
    contact: {
      type: Object,
      default: () => ({ type: '', value: '' }),
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    removeContact() {
      this.$emit('removeContact', this.contact);
    },
    update(key, value) {
      this.$emit('input', { ...this.contact, [key]: value });
    }
  }
};
</script>
