import Parse from '@kickbox/common-util/src/parse';
import { query } from '@kickbox/common-util/src/util/parse';

export default {
  formatUnit(unit) {
    return {
      id: unit.id,
      name: unit.get('name'),
      admins: unit.get('admins'),
      active: unit.get('active')
    };
  },
  async getCurrentUserUnits(company) {
    const adminEmail = await Parse.User.current().get('username');
    const adminUnits = await query('Unit')
      .equalTo('company', company)
      .equalTo('admins', adminEmail)
      .find();
    return adminUnits.map((u) => this.formatUnit(u));
  },
  async getAdminByUnit(name) {
    const adminUnit = await query('Unit')
      .equalTo('name', name)
      .first();
    return adminUnit.get('admins');
  }
};
