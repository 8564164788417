const state = {
  isLoading: {
    serviceProviders: false,
    transactions: false,
    coinsTransactions: false,
    campaigns: false,
    languages: false,
    translations: false,
    emails: false,
    offers: false,
    users: false,
    tags: false,
    emailCoaching: false,
    units: false,
    other: false,
    skills: false,
    certificates: false
  }
};

const getters = {
  isLoading: (st) => st.isLoading
};

const mutations = {
  setIsLoading(st, payload) {
    st.isLoading = { ...st.isLoading, ...payload };
  }
};

const actions = {
  setIsLoading({ commit }, payload) {
    commit('setIsLoading', payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
