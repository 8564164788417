import Parse from '@kickbox/common-util/src/parse';

export default {
  getCurrentUser() {
    return Parse.User.current();
  },
  fetchUsersByEmail(emailsArray) {
    return Parse.Cloud.run('getUsersByEmail', { emailsArray });
  },
  createUser(email, companyId, isAdmin) {
    return Parse.Cloud.run('createUser', { email, companyId, isAdmin });
  },
  async logout() {
    await Parse.User.logOut();
    localStorage.removeItem('company');
    // This a workaround, Logout and reload, you can only login after refreshing the page.
    window.location.href = '/';
  },
  checkIfUserExists(email) {
    return Parse.Cloud.run('checkIfUserExists', { email });
  },
  async createUsersIfDoesNotExists(emailsArray, companyId, isAdmin = false) {
    if (emailsArray.length) {
      const users = await this.fetchUsersByEmail(emailsArray);
      const usersToBeCreated = emailsArray
        .filter((e) => !users.filter((u) => u.get('username') === e).length);
      const createdUsers = await Promise.all(
        usersToBeCreated.map((user) => this.createUser(user, companyId, isAdmin))
      );
      return {
        existingUsers: users.map((_) => _.get('username')),
        createdUsers,
      };
    }
    return Promise.resolve();
  },
  addCompanyIfUserDoesNotHaveOne(emailsArray, company) {
    return this.fetchUsersByEmail(emailsArray)
      .then((users) => {
        users.filter((user) => !user.get('company'))
          .forEach((user) => Parse.Cloud.run('updateUser', { id: user.id, companyId: company.id }));
      })
      .catch((error) => {
        console.log('addCompanyIfUserDoesNotHaveOne', error);
      });
  }
};
