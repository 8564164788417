<template>
  <v-container>
    <v-card class="content-card-padding">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <app-button
          secondary
          @click="showEditDialog = true"
        >
          Add tag
        </app-button>
        <download-excel
          :data="searchResult"
          :fields="exportDataFields"
          name="download.xls"
        >
          <app-button
            secondary
            :disabled="searchResult.length === 0"
            class="ml-4"
          >
            Export List
          </app-button>
        </download-excel>
      </v-card-title>
      <v-data-table
        :footer-props="{
          itemsPerPageText: 'Tags per page:',
          itemsPerPageOptions: [15, 30, 45, { text: 'All', value: -1 }]
        }"
        :headers="headers"
        :items="tags"
        :search="search"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :no-data-text="noData"
        class="table-container"
        item-key="creationDate"
      >
        <template
          v-if="isLoading.tags"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #item="{ item }"
        >
          <tr>
            <td>{{ item.name }}</td>
            <td v-if="!item.disabled">
              <v-icon green>
                check_circle
              </v-icon>
            </td>
            <td v-else>
              <v-icon>block</v-icon>
            </td>
            <td>{{ projectCount(item.id) }}</td>
            <td>
              <v-menu
                bottom
                left
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editTagDetails(item)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!isLastActiveTag(item)"
                    @click="removeTagWarning(item)"
                  >
                    <v-list-item-title>Remove</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <manage-tag-dialog
      v-if="showEditDialog"
      :show-dialog="showEditDialog"
      :prevent-disable="preventDisable(tag)"
      :tags="tags"
      :tag="tag"
      @close-dialog="closeTagDialog"
      @save="saveTag"
    />
    <confirm-dialog
      v-if="showDeleteTagDialog"
      :show="showDeleteTagDialog"
      :confirm-click="removeTag"
      @close="showDeleteTagDialog = false"
    >
      Are you sure you want to delete this tag: "{{ tag.name }}"?
    </confirm-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmDialog from '../ConfirmDialog';
import ManageTagDialog from './manageTagDialog';
import TableSkeletonLoader from '../TableSkeletonLoader';

export default {
  components: {
    ManageTagDialog,
    ConfirmDialog,
    TableSkeletonLoader
  },
  data() {
    return {
      exportDataFields: {
        Name: 'name',
        // workaround for: https://github.com/jecovier/vue-json-excel/issues/76
        Enabled: { callback: (tag) => (!tag.disabled).toString() },
        Projects: { callback: (tag) => this.projectCount(tag.id) },
      },
      search: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Enabled', value: 'disabled', align: 'left' },
        { text: 'Projects', value: 'projectCount', align: 'left' },
        { sortable: false, width: 30 },
      ],
      showEditDialog: false,
      showDeleteTagDialog: false,
      tag: null
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'projectMap',
      'tags',
    ]),
    noData() {
      return this.isLoading.tags ? 'loading...' : 'No tags...';
    },
    searchResult() {
      return this.tags.filter((tag) => this.customFilter(null, this.search, tag));
    }
  },
  created() {
    this.$store.dispatch('getTags', true);
  },
  methods: {
    isLastActiveTag(currentTag) {
      const activeTags = this.tags.filter((tag) => !tag.disabled);

      if (activeTags.length === 1 && currentTag) {
        return activeTags[0].id === currentTag.id && !currentTag.disabled;
      }

      return false;
    },
    allowTagRemoval(tag) {
      return !this.isLastActiveTag(tag) && !this.isUsed(tag.id);
    },
    preventDisable(tag) {
      return this.isLastActiveTag(tag)
        || (tag && !tag.disabled && this.isUsed(tag.id));
    },
    isUsed(tagId) {
      return this.projectCount(tagId) > 0;
    },
    projectCount(tagId) {
      return this.projectMap[tagId] || 0;
    },
    removeTagWarning(tag) {
      this.tag = tag;
      this.showDeleteTagDialog = true;
    },
    removeTag() {
      this.$store.dispatch('removeTag', this.tag.id);
      this.closeTagDialog();
    },
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return String(item.name).toLowerCase().includes(customSearch)
        || String(this.projectCount(item.id)).includes(customSearch)
        || (item.disabled ? 'disabled' : 'enabled').includes(customSearch);
    },
    customSort(items, sortProp, isDesc) {
      items.sort((self, other) => {
        switch (sortProp[0]) {
          case 'projectCount':
            if (this.projectCount(self.id) === this.projectCount(other.id)) {
              return 0;
            }
            return this.projectCount(self.id) < this.projectCount(other.id) ? -1 : 1;
          default:
            if (self[sortProp] === other[sortProp]) {
              return 0;
            }
            return self[sortProp] < other[sortProp] ? -1 : 1;
        }
      });
      return isDesc[0] ? items.reverse() : items;
    },
    saveTag(tag) {
      this.$store.dispatch('updateTag', tag);
      this.closeTagDialog();
    },
    editTagDetails(tag) {
      this.tag = tag;
      this.showEditDialog = true;
    },
    closeTagDialog() {
      this.tag = null;
      this.showEditDialog = false;
      this.showDeleteTagDialog = false;
    }
  }
};
</script>

<style>
  .tag-container .input-group__details {
    min-height: 0 !important;
  }
</style>
