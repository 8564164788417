<template>
  <tbody>
    <tr
      v-for="i in rows"
      :key="i"
    >
      <template v-for="header in headers">
        <td
          :key="header.value"
          class="pa-4"
        >
          <v-skeleton-loader type="text" />
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Number,
      default: 12
    }
  }
};
</script>
