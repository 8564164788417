import Parse from '@kickbox/common-util/src/parse';
import fileService from '@kickbox/common-util/service/fileService';

async function updateImage(item, imageProperty) {
  const objectInfo = {
    type: 'InnoHub',
    id: item.id,
    field: 'cardImage'
  };
  const destination = `file-sharing/innovation-hub/${item.type}`;
  return fileService
    .uploadImage(imageProperty.file, imageProperty.oldUrl, 'public-read', objectInfo, destination);
}

export default {
  async getInnoHubContent(excludeDisabled = true) {
    return Parse.Cloud.run('getInnoHubContent', { excludeDisabled });
  },
  async saveInnoHubContent(itemProperties, imageProperty) {
    const savedItem = await Parse.Cloud.run('saveInnoHubContent', { item: itemProperties });
    if (!imageProperty.sameImage) {
      const imageUrl = await updateImage(savedItem, imageProperty);
      const item = {
        ...savedItem,
        cardImage: imageUrl
      };
      return Parse.Cloud.run('saveInnoHubContent', { item });
    }
    return savedItem;
  }
};
