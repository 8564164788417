/* eslint-disable */
'use strict';

var minUserNameLength = 3;
var minTextFieldLength = 0;

var formValidationFunctions = {
  emailIsValid: function emailIsValid(email, domainToMatch) {
    if (email == null) {
      return false;
    }
    var parts = email.split('@');
    if (parts.length !== 2) {
      return false;
    }
    var domain = parts[1].split('.');
    if (domain.length < 2 || domain.length > 4) {
      return false;
    }
    if (domainToMatch == null) {
      return true;
    }

    return domain.join('.').toLowerCase() === domainToMatch.toLowerCase();
  },
  fullNameIsValid: function fullNameIsValid(fullName) {
    return fullName.length >= minUserNameLength;
  },
  isWholeNumber: function isWholeNumber(number) {
    return Number.isInteger(number);
  },
  isPositiveNumber: function isPositiveNumber(number) {
    return Number.isInteger(number) && number > 0;
  },
  generalTextFieldValid: function generalTextFieldValid(fieldText) {
    return fieldText != null && fieldText.length > minTextFieldLength;
  },
  validVideoUrl: function validVideoUrl(value) {
    const youtubePattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
    return youtubePattern.test(value);
  }
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

var contactInfoValues = [{ text: 'Phone', value: 'Phone' }, { text: 'LinkedIn', value: 'LinkedIn' }, { text: 'Facebook', value: 'Facebook' }, { text: 'Twitter', value: 'Twitter' }, { text: 'Website', value: 'Website' }, { text: 'Intranet', value: 'Intranet' }, { text: 'XING', value: 'XING' }, { text: 'MS Teams', value: 'MS Teams' },  { text: 'GitHub', value: 'GitHub' }];

var offerCategoryMap = [{ key: 'all-categories', name: 'All categories' }, { key: 'legal', name: 'Legal' }, { key: 'accelerators', name: 'Accelerators' }, { key: 'film-and-photo', name: 'Film & Photo Production' }, { key: 'hr-and-team', name: 'HR & Team' }, { key: 'design-and-proto', name: 'Design Thinking & Prototyping' }, { key: 'ui-ux', name: 'Graphic & Design (UI/UX)' }, { key: 'business-dev', name: 'Business Development' }, { key: 'programming', name: 'Programming' }, { key: 'marketing', name: 'Marketing' }];

function getUserLanguage(user, company) {
  let language = user ? user.get('preferredLanguage') : 'EN';
  if (company && !company.get('enabledLanguages').includes(language)) {
    language = company.get('language');
  }
  return language;
}

function randomPassword(length, customChars) {
  var chars = customChars || 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
  var pass = '';
  for (var x = 0; x < length; x += 1) {
    pass += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return pass;
}

function onWindows7Explorer11(userAgent = window.navigator.userAgent) {
  return userAgent.includes('Windows NT 6.1') && userAgent.includes('Trident/7.0');
}

function onDesktop() {
  const mobileDevice = /iphone|mobile|ipad|tablet|phone/.test(window.navigator.userAgent.toLowerCase());
  const touchDesktop = !!navigator.userAgent.match(/Tablet PC/i);
  return !mobileDevice || touchDesktop;
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function removeDuplicateOnArray(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
}

function createNestedObject(obj, keyPath, value) {
  var keyArr = keyPath.split('.');
  var lastKeyIndex = keyArr.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    var key = keyArr[i];

    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keyArr[lastKeyIndex]] = value;
}

// Replace the variables object used in defaultContent or customContent in the "text" variable (ie:
// {{companyName}} ) with the corresponding text (ie: NGTI)
function replaceVariablesToText(variables, text) {
  return Object.entries(variables).reduce(function (newMessage, variable) {
    var key = variable[0];
    var value = variable[1];
    return newMessage && newMessage.replace(new RegExp('({{' + key + '}}|{' + key + '})', 'g'), value);
  }, text);
}

// replace the text/tags/classes generated automatically in the vue-editor
function editorHtmlFormat(template) {
  const mapObj = {
    'class="ql-align-center"': 'style="text-align: center"',
    'class="ql-align-right"': 'style="text-align: right"',
    'class="ql-align-justify"': 'style="text-align: justify"',
    'class="ql-indent-1"': 'style="margin-left: 4.5em"',
    'class="ql-indent-2"': 'style="margin-left: 7.5em"',
    'class="ql-indent-3"': 'style="margin-left: 10.5em"',
    '<p>&nbsp;</p>': '',
    '<p class="ql-align-center"><br></p>': '',
    '<p class="ql-align-right"><br></p>': '',
    '<p class="ql-align-justify"><br></p>': '',
    '<p><br></p>': '<br>',
    '<p>' : '<p style="margin: 0; padding: 0">',
    '<h1>' : '<h1 style="margin: 0; padding: 0; font-weight: 400">',
    '<h2>' : '<h2 style="margin: 0; padding: 0; font-weight: 400">',
    '<h3>' : '<h3 style="margin: 0; padding: 0; font-weight: 400">',
    '<h4>' : '<h4 style="margin: 0; padding: 0; font-weight: 600">',
    '<h5>' : '<h5 style="margin: 0; padding: 0;">',
    '<h6>' : '<h6 style="margin: 0; padding: 0;">',
    '<ul>': '<ul style="list-style-position: inside; margin: 0; padding: 0">',
    '<ol>': '<ol style="list-style-position: inside; margin: 0; padding: 0">'
  };
  return template
    .replace(/class="(ql-align-center|ql-align-right|ql-align-justify)"|<p>&nbsp;<\/p>|<p><br><\/p>|<p class="(ql-align-center|ql-align-right|ql-align-justify)"><br><\/p>|class="(ql-indent-1|ql-indent-2|ql-indent-3)"|<ul>|<ol>|<p>|<h1>|<h2>|<h3>|<h4>|<h5>|<h6>/gi, function (matched) {
    return mapObj[matched];
  });
}

function customLinkSanitizer(Quill) {
  const Link = Quill.import('formats/link');
// Override the existing property on the Quill global object and add custom protocols
  Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

  class CustomLinkSanitizer extends Link {
    static sanitize(url) {
      // Run default sanitize method from Quill
      const sanitizedUrl = super.sanitize(url);

      // Not whitelisted URL based on protocol so, let's return `blank`
      if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

      // Verify if the URL already have a whitelisted protocol
      const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST
        .some((protocol) => sanitizedUrl.startsWith(protocol));

      if (hasWhitelistedProtocol) return sanitizedUrl;

      // if not, then append only 'http' not to be a relative URL
      return `https://${sanitizedUrl}`;
    }
  }
  Quill.register(CustomLinkSanitizer, true);
}

function validateRequest(requiredParams, requestParams) {
  requiredParams.forEach((key) => {
    if (!requestParams.hasOwnProperty(key)) {
      throw new Error(`${key} is missing`);
    }
  });
  return true;
}

// Utility function to publish messages to a queue channel
function publishToChannel(channel, { routingKey, exchangeName, msg }) {
  return new Promise((resolve, reject) => {
    channel.publish(exchangeName,
      routingKey,
      Buffer.from(JSON.stringify(msg), 'utf-8'),
      {
        persistent: true,
        durable: true
      },
      function (err, ok) {
        if (err) {
          return reject(err);
        }

        return resolve(ok);
      });
  });
}

var defaultSanitizeOptions = {
  allowedTags: ['a', 'b', 'span', 'i', 'em', 's', 'u', 'strong', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'li', 'ol', 'ul', 'p', 'br', 'hr'],
  selfClosing: ['br', 'hr'],
  allowedAttributes: {
    a: ['href', 'target'],
    li: ['class'],
    '*': ['style']
  }
};

var metaSanitizeOptions = {
  allowedTags: ['a', 'b', 'span', 'i', 'em', 's', 'u', 'strong', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'li', 'ol', 'ul', 'p', 'br', 'hr', 'img', 'iframe'],
  selfClosing: ['br', 'hr', 'img'],
  allowedAttributes: {
    a: ['href', 'target'],
    li: ['class'],
    img: ['src', 'alt', 'width', 'height'],
    iframe: ['src', 'width', 'height', 'class', 'frameborder', 'allowfullscreen'],
    '*': ['style']
  }
};

var exportSanitizeOptions = {
  allowedTags: [],
  allowedAttributes: {}
}

function getConvertedKey(key) {
  const fileKey = key.replace('videos/', '');
  return `videos-converted/${fileKey}`;
}

function buildTagDTO(tags) {
  const tagsArray = Array.isArray(tags) ? tags : [tags];
  return tagsArray.filter((tag) => tag && typeof tag.get === 'function')
    .map((tag) => ({
      id: tag.id,
      name: tag.get('name'),
      disabled: !!tag.get('disabled'),
      global: !!tag.get('global')
    }));
}

function getSearchPlaceholderTranslationKey(routeName) {
  switch (routeName) {
    case 'services':
      return 'search_bar.search.services';
    case 'people':
      return 'search_bar.search.people';
    case 'projects':
      return 'search_bar.search.projects';
    default:
      return routeName;
  }
}

var KICKBOX_ROLES = {
  BLUELION_ADMIN: 'BluelionAdmin',
  COMPANY_ADMIN: 'CompanyAdmin',
  VIEWER: 'Viewer',
  KICKBOXER: 'Kickboxer',
  EXPERT: 'Expert',
  KICKANGEL: 'KickAngel',
  SERVICE_PROVIDER: 'ServiceProvider'
};

var PENDING = {
  name: 'pending',
  title: 'Pending'
};
var REDBOX_IDEA = {
  name: 'redbox',
  title: 'Redbox',
  subTitle: 'Idea'
};
var REDBOX_FUNDING = {
  name: 'redboxFunding',
  title: 'Redbox',
  subTitle: 'Funding'
};
var BLUEBOX = {
  name: 'bluebox',
  title: 'Bluebox'
};
var GOLDBOX = {
  name: 'goldbox',
  title: 'Goldbox'
};
var ALUMNI = {
  name: 'alumni',
  title: 'Alumni'
};
var APPROVED = {
  name: 'approved',
  title: 'Approved'
};
var REJECTED = {
  name: 'rejected',
  title: 'Rejected'
};
var phases = {
  pending: PENDING,
  approved: APPROVED,
  rejected: REJECTED,
  redbox: REDBOX_IDEA,
  redboxFunding: REDBOX_FUNDING,
  bluebox: BLUEBOX,
  goldbox: GOLDBOX,
  alumni: ALUMNI,
};

var PHASE_ORDER = {
  [REDBOX_IDEA.name]: 1,
  [REDBOX_FUNDING.name]: 2,
  [BLUEBOX.name]: 3,
  [GOLDBOX.name]: 4,
};

var REDIRECT_ROUTE = 'redirectRoute';

var VALID_QUEUE_JOB_TYPES = ['sendEmail'];

module.exports = {
  formValidation: formValidationFunctions,
  contactInfoValues: contactInfoValues,
  offerCategoryMap: offerCategoryMap,
  onDesktop: onDesktop,
  onWindows7Explorer11: onWindows7Explorer11,
  isObjectEmpty: isObjectEmpty,
  validateRequest: validateRequest,
  createNestedObject: createNestedObject,
  removeDuplicateOnArray: removeDuplicateOnArray,
  randomPassword: randomPassword,
  getUserLanguage: getUserLanguage,
  getRandomInt: getRandomInt,
  getConvertedKey: getConvertedKey,
  phases: phases,
  PENDING: PENDING,
  REDBOX_IDEA: REDBOX_IDEA,
  REDBOX_FUNDING: REDBOX_FUNDING,
  BLUEBOX: BLUEBOX,
  GOLDBOX: GOLDBOX,
  ALUMNI: ALUMNI,
  PHASE_ORDER: PHASE_ORDER,
  APPROVED: APPROVED,
  REJECTED: REJECTED,
  REDIRECT_ROUTE: REDIRECT_ROUTE,
  KICKBOX_ROLES: KICKBOX_ROLES,
  defaultSanitizeOptions: defaultSanitizeOptions,
  metaSanitizeOptions: metaSanitizeOptions,
  exportSanitizeOptions: exportSanitizeOptions,
  buildTagDTO: buildTagDTO,
  editorHtmlFormat: editorHtmlFormat,
  replaceVariablesToText: replaceVariablesToText,
  customLinkSanitizer: customLinkSanitizer,
  publishToChannel: publishToChannel,
  validQueueJobTypes: VALID_QUEUE_JOB_TYPES,
  getSearchPlaceholderTranslationKey: getSearchPlaceholderTranslationKey
};
