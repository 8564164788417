import Parse from '../parse';

export function query(Class) {
  return new Parse.Query(Class);
}

export function get(Class, id) {
  return query(Class).get(id);
}

export function instance(Class, params) {
  return new Parse.Object(Class, params);
}

export function getTagInstance({ id }) {
  return instance('Tag', { id });
}
