export default function removeEmptyPropertiesFromObject(object, _fields) {
  const fields = _fields || Object.keys(object);
  return fields.reduce((propsObj, field) => {
    const value = object[field];
    const isArray = Array.isArray(value);
    if ((isArray && value.length > 0) || (!isArray && value)) {
      propsObj[field] = value;
    }
    return propsObj;
  }, {});
}
