import {
  KICKBOX_ROLES,
  ALUMNI,
  REJECTED,
  PENDING,
  REDBOX_IDEA,
  REDBOX_FUNDING,
  BLUEBOX,
  GOLDBOX
} from '@kickbox/common-util';
import * as FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import moment from 'moment';
import TRANSACTION_TYPE from '@kickbox/common-util/constants/transaction-type';
import store from '@/store';

/**
 * returns all projects where the given phase is between the start- and end-date
 * @param {Object} phase phase
 * @param {moment} from start date
 * @param {moment} to end date
 */
function getProjectsByPhase(phase, from, to) {
  return store.getters.dashboardProjects.filter((project) => project.phases.find((ph) => {
    if (ph.name !== phase.name) return false;
    return ph.createdAt.isBetween(from, to, 'day', '[]');
  }) && !project.disabled);
}

/**
 * Returns the full name of the phase.
 * Useful for redbox idea and funding.
 * @param {object} phase phase object
 */
function getFullPhaseName(phase) {
  if (phase.subTitle) {
    return `${phase.title} ${phase.subTitle}`;
  }
  return phase.title;
}

/**
 * returns all users that are registered between the given time
 * @param {Object[]} users all users that should be filtered
 * @param {moment} from start date
 * @param {moment} to end date
 */
function getUsersBetweenDates(from, to) {
  return store.getters.users.filter((user) => moment(user.createdAt).isBetween(from, to, 'day', '[]'));
}

/**
 * returns all users that are registered between the given time
 * and have the given role
 * @param {Object[]} users // users to filter
 * @param {string} role // role of the users
 * @param {moment} from // start date
 * @param {moment} to // end date
 */
function getUsersForRoleAndDateRange(role, from, to) {
  return getUsersBetweenDates(from, to)
    .filter((user) => user.role.find((r) => r === role) || role === KICKBOX_ROLES.VIEWER);
}

/**
 * returns the month names for the given time as key in an object
 * @param {moment} from start date
 * @param {moment} to end date
 */
function getLineChartDataLabels(from, to) {
  const chartDataLabels = {};
  const date = from.clone();
  while (date.isBefore(to)) {
    chartDataLabels[date.format('YYYY/MM')] = 0;
    date.add(1, 'month');
  }
  return chartDataLabels;
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Returns an array with colors.
 * The colors are always as much distinguishable as possible
 * and are also not random. Everytime when this method is called
 * it returs the same colors.
 * The amount of colors can be infnently long.
 * @param {number} [amount = 20] amount of colors
 */
function getColors(amount = 20) {
  const colors = [];
  const hueDelta = Math.trunc(360 / amount);

  for (let i = 0; i < amount; i += 1) {
    const hue = i * hueDelta;
    const lightness = randomIntFromInterval(35, 80);
    const saturation = randomIntFromInterval(40, 100);
    colors.push(`hsl(${hue},${saturation}%,${lightness}%)`);
  }
  return colors;
}

const phases = {
  [PENDING.name]: {
    name: PENDING.name,
    title: PENDING.title,
    color: '#8d98a5'
  },
  [REDBOX_IDEA.name]: {
    name: REDBOX_IDEA.name,
    title: `${REDBOX_IDEA.title} ${REDBOX_IDEA.subTitle}`,
    color: '#f93b3b'
  },
  [REDBOX_FUNDING.name]: {
    name: REDBOX_FUNDING.name,
    title: `${REDBOX_FUNDING.title} ${REDBOX_FUNDING.subTitle}`,
    color: '#f93b3b'
  },
  [BLUEBOX.name]: {
    name: BLUEBOX.name,
    title: BLUEBOX.title,
    color: '#2b94fc'
  },
  [GOLDBOX.name]: {
    name: GOLDBOX.name,
    title: GOLDBOX.title,
    color: '#f5a623'
  },
  [ALUMNI.name]: {
    name: ALUMNI.name,
    title: ALUMNI.title,
    color: '#8d98a5'
  },
  [REJECTED.name]: {
    name: REJECTED.name,
    title: REJECTED.title,
    color: '#8d98a5'
  }
};

function availableAnalytics() {
  const analytics = {
    Projects: [
      {
        value: 'projects-per-phase',
        label: 'Projects per phase'
      },
      {
        value: 'projects-over-time',
        label: 'Projects over time'
      }
    ],
    Engagement: [
      {
        value: 'top-engagement',
        label: 'Top Engagement'
      },
      {
        value: 'engagement-numbers',
        label: 'Engagement Numbers'
      }
    ],
    Users: [
      {
        value: 'user-numbers',
        label: 'User Numbers'
      },
      {
        value: 'users-over-time',
        label: 'Registered users over time'
      },
      {
        value: 'viewer-per-unit',
        label: 'Viewers per unit'
      },
      {
        value: 'kickboxer-per-unit',
        label: 'Kickboxers per unit'
      },
      {
        value: 'expert-per-unit',
        label: 'Experts per unit'
      },
      {
        value: 'top-experts-skills',
        label: 'Top 10 skills of experts'
      }
    ]
  };

  if (store.getters.company && store.getters.company.features[FEATURE_NAME.COINS]) {
    analytics.Coins = [
      {
        value: 'coins-numbers',
        label: 'Coins Analytics'
      }
    ];
  }

  if (store.getters.company && store.getters.company.features[FEATURE_NAME.SERVICES]) {
    analytics.Services = [
      {
        value: 'top-services',
        label: 'Top services'
      },
      {
        value: 'service-numbers',
        label: 'Service numbers'
      }
    ];
  }
  return analytics;
}

// Array with all analytics that are selected by default
function defaultAnalytics () {
  return Object.keys(availableAnalytics())
    .flatMap((key) => availableAnalytics()[key].map((analytic) => analytic.value));
}

function selectedAnalytics() {
  if (store.getters.userSettings && store.getters.userSettings.analytics) {
    return defaultAnalytics().filter((a) => store.getters.userSettings.analytics.includes(a));
  }
  return defaultAnalytics();
}

function categoryEnabled(category) {
  // save selected analytics to variable, to that the function doesn't get executed for every stat
  const analyticsSelected = selectedAnalytics();
  return availableAnalytics()[category] && availableAnalytics()[category]
    .find((a) => analyticsSelected.includes(a.value));
}

/**
 * Get the sum of transactions.
 *
 * @param {object[]} transactions
 */
function getAmountOfCoins(transactions) {
  if (transactions.length) {
    return transactions.reduce((total, transaction) => total + transaction.amount, 0);
  }
  return 0;
}

/**
 * Returns the entry date of a spesific phase from a project.
 * The date is formated as string for the export.
 *
 * @param {object} project
 * @param {string} phaseName
 */
function phaseEntryDate(project, phaseName) {
  const phase = project.phases.find((p) => p.name === phaseName);
  if (phase) return moment(phase.createdAt);
  return null;
}

// Map the projects to the format needed for the export
function exportProjectsData(from, to) {
  /**
   * Get all projects that are either created within the time selected
   * or have entered any phase within the time selected and are not deleted
   */
  const projectsFiltered = store.getters.dashboardProjects.filter((project) => {
    // Filter disabled projects
    if (project.disabled) return false;

    // Check for project or phase creation date
    if (project.createdAt.isBetween(from, to, 'day', '[]')) return true;
    return project.phases.some((phase) => phase.createdAt.isBetween(from, to, 'day', '[]'));
  });

  // Map the project with the entry dates of all phases.
  return projectsFiltered.map((project) => ({
    ...project,
    redboxIdeaEntryDate: phaseEntryDate(project, REDBOX_IDEA.name),
    redboxFundingEntryDate: phaseEntryDate(project, REDBOX_FUNDING.name),
    blueboxEntryDate: phaseEntryDate(project, BLUEBOX.name),
    goldboxEntryDate: phaseEntryDate(project, GOLDBOX.name),
    tags: project.tags.map((t) => t.name),
    isAlumni: project.isAlumni ? 'yes' : 'no',
    disabled: project.isRejected ? 'yes' : 'no'
  }));
}

// Get only the transactions created within the given time
function transactionsBetweenDates(from, to) {
  return store.getters.dashboardTransactions
    .filter((transaction) => transaction.createdAt.isBetween(from, to, 'day', '[]'));
}

// All transactions that requested a service within the given time
function serviceTransactionsBetweenDates(from, to) {
  return transactionsBetweenDates(from, to)
    .filter((transaction) => transaction.type === TRANSACTION_TYPE.REQUEST);
}

function reactionsBetweenDates(from, to) {
  return store.getters.reactions
    .filter((reaction) => moment(reaction.createdAt).isBetween(from, to, 'day', '[]'));
}

/**
 * Filters the projects and returns only the projects that are not disabled and not alumni
 * for the given phase
 *
 * @param {string} phaseName name of the phase
 */
function activeProjectsByPhase(phaseName) {
  return store.getters.dashboardProjects
    .filter((project) => !project.disabled)
    .filter((project) => {
      switch (phaseName) {
        case ALUMNI.name:
          return project.isAlumni && !project.isRejected;
        case REJECTED.name:
          return project.isRejected;
        case project.phase.name:
          return !project.isAlumni && !project.isRejected;
        default:
          return false;
      }
    })
    .sort((a, b) => b.phase.startDate.valueOf() - a.phase.startDate.valueOf());
}

/**
 * Filters the projects and returns only the projects that are not disabled ond not alumni
 * for the given campaign
 *
 * @param {string} campaignId id of the campaign
 */
function projectsByCampaign(campaignId) {
  return store.getters.dashboardProjects
    .filter((project) => project.campaign
      && project.campaign.id === campaignId
      && !project.isAlumni
      && !project.isRejected
      && !project.disabled)
    .reverse();
}

export default {
  getProjectsByPhase,
  getFullPhaseName,
  getUsersBetweenDates,
  getLineChartDataLabels,
  getColors,
  getUsersForRoleAndDateRange,
  phases,
  availableAnalytics,
  defaultAnalytics,
  getAmountOfCoins,
  exportProjectsData,
  transactionsBetweenDates,
  serviceTransactionsBetweenDates,
  activeProjectsByPhase,
  projectsByCampaign,
  selectedAnalytics,
  categoryEnabled,
  reactionsBetweenDates
};
