import { mapGetters } from 'vuex';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import CompanyService from '../services/companyService';
import UserService from '../services/userService';

export default {
  data() {
    return {
      loading: {
        admins: false,
        unitAdmins: false,
        coaches: false
      }
    };
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    listItemActions() {
      const actions = {
        admins: [
          {
            title: 'Make Internal Coach',
            onClick: (emailAddress) => this.moveEmailFromTo(
              ADMIN_ROLES.COMPANY_ADMINS, ADMIN_ROLES.INTERNAL_COACHES, emailAddress
            )
          },
          {
            title: 'Make External Coach',
            onClick: (emailAddress) => this.moveEmailFromTo(
              ADMIN_ROLES.COMPANY_ADMINS, ADMIN_ROLES.EXTERNAL_COACHES, emailAddress
            )
          }
        ],
        unitAdmins: [
          {
            title: 'Make Company Admin',
            onClick: (emailAddress) => this.moveEmailFromTo(
              ADMIN_ROLES.UNIT_ADMINS, ADMIN_ROLES.COMPANY_ADMINS, emailAddress
            )
          },
          {
            title: 'Make Internal Coach',
            onClick: (emailAddress) => this.moveEmailFromTo(
              ADMIN_ROLES.UNIT_ADMINS, ADMIN_ROLES.INTERNAL_COACHES, emailAddress
            )
          },
          {
            title: 'Make External Coach',
            onClick: (emailAddress) => this.moveEmailFromTo(
              ADMIN_ROLES.UNIT_ADMINS, ADMIN_ROLES.EXTERNAL_COACHES, emailAddress
            )
          }
        ],
        coaches: {
          [ADMIN_ROLES.INTERNAL_COACHES]: [
            {
              title: 'Make Company Admin',
              onClick: (emailAddress) => this.moveEmailFromTo(
                ADMIN_ROLES.INTERNAL_COACHES, ADMIN_ROLES.COMPANY_ADMINS, emailAddress
              )
            },
            {
              title: 'Make External Coach',
              onClick: (emailAddress) => this.moveEmailFromTo(
                ADMIN_ROLES.INTERNAL_COACHES, ADMIN_ROLES.EXTERNAL_COACHES, emailAddress
              )
            }
          ],
          [ADMIN_ROLES.EXTERNAL_COACHES]: [
            {
              title: 'Make Company Admin',
              onClick: (emailAddress) => this.moveEmailFromTo(
                ADMIN_ROLES.EXTERNAL_COACHES, ADMIN_ROLES.COMPANY_ADMINS, emailAddress
              )
            },
            {
              title: 'Make Internal Coach',
              onClick: (emailAddress) => this.moveEmailFromTo(
                ADMIN_ROLES.EXTERNAL_COACHES, ADMIN_ROLES.INTERNAL_COACHES, emailAddress
              )
            }
          ]
        }
      };

      // this.company.features is an array of strings in Admin but an array of objects in Meta
      if ((Array.isArray(this.company.features)
          && this.company.features.includes(FEATURE_NAME.MULTI_ENTITY))
        || this.company.features[FEATURE_NAME.MULTI_ENTITY]) {
        actions.admins.unshift({
          title: 'Make Unit Admin',
          onClick: (emailAddress) => this.moveEmailFromTo(
            ADMIN_ROLES.COMPANY_ADMINS, ADMIN_ROLES.UNIT_ADMINS, emailAddress
          )
        });
        actions.coaches[ADMIN_ROLES.INTERNAL_COACHES].push({
          title: 'Make Unit Admin',
          onClick: (emailAddress) => this.moveEmailFromTo(
            ADMIN_ROLES.INTERNAL_COACHES, ADMIN_ROLES.UNIT_ADMINS, emailAddress
          )
        });
        actions.coaches[ADMIN_ROLES.EXTERNAL_COACHES].push({
          title: 'Make Unit Admin',
          onClick: (emailAddress) => this.moveEmailFromTo(
            ADMIN_ROLES.EXTERNAL_COACHES, ADMIN_ROLES.UNIT_ADMINS, emailAddress
          )
        });
      }
      return actions;
    },
    coaches() {
      return {
        [ADMIN_ROLES.INTERNAL_COACHES]: this.company.internalCoaches,
        [ADMIN_ROLES.EXTERNAL_COACHES]: this.company.externalCoaches
      };
    }
  },
  methods: {
    isCoach(adminUserType) {
      return [ADMIN_ROLES.COACHES, ADMIN_ROLES.INTERNAL_COACHES, ADMIN_ROLES.EXTERNAL_COACHES]
        .includes(adminUserType);
    },
    async saveCoaches(type, emailsToUpdate, move = false) {
      const otherCoachKey = ADMIN_ROLES.INTERNAL_COACHES === type
        ? ADMIN_ROLES.EXTERNAL_COACHES
        : ADMIN_ROLES.INTERNAL_COACHES;
      const otherStoreCoaches = this.company[otherCoachKey];

      // Vuex store is an object containing internal/external coaches,
      // we need to update the current coaches
      const all = {
        [otherCoachKey]: otherStoreCoaches,
        [type]: emailsToUpdate.all
      };
      return this.save(ADMIN_ROLES.COACHES, { ...emailsToUpdate, all }, move);
    },
    saveToStore(adminUserType, emailsToUpdate) {
      if (this.isCoach(adminUserType)) {
        const internalCoaches = emailsToUpdate.all[ADMIN_ROLES.INTERNAL_COACHES];
        const externalCoaches = emailsToUpdate.all[ADMIN_ROLES.EXTERNAL_COACHES];
        const allEmails = [
          ...internalCoaches,
          ...externalCoaches
        ];
        this.updateStore(internalCoaches, ADMIN_ROLES.INTERNAL_COACHES);
        this.updateStore(externalCoaches, ADMIN_ROLES.EXTERNAL_COACHES);
        this.updateStore(allEmails, adminUserType);
      } else {
        this.updateStore(emailsToUpdate.all, adminUserType);
      }
    },
    async save(adminUserType, emailsToUpdate, move = false) {
      if (adminUserType === ADMIN_ROLES.COMPANY_ADMINS && emailsToUpdate.remove.includes(this.company.parseObject.get('welcomeMessageAdmin'))) {
        await CompanyService.updateWelcomeMessageAdmin('');
      }
      if (!move && emailsToUpdate.add.length && this.checkIfEmailsExists(emailsToUpdate.add)) {
        this.$store.dispatch('showSnackBar', { text: 'You are trying to safe a duplicate mail entry.' });
        return;
      }
      this.loading[adminUserType] = true;

      // We want to remove the coach and unit assignment from a removed user
      // except when he is getting moved to another role and thus reassigned immediately.
      if (!move && emailsToUpdate.remove.length) {
        await CompanyService.removeCoachesFromProjects(emailsToUpdate.remove);
        await CompanyService.removeAdminsFromUnits(emailsToUpdate.remove);
      }

      // When the user gets moved to the coach role,
      // we still want to delete the unit assignments because a Coach can't be assigned to Units.
      if (move && emailsToUpdate.add && adminUserType === ADMIN_ROLES.COACHES) {
        await CompanyService.removeAdminsFromUnits(emailsToUpdate.add);
      }

      await CompanyService.updateAdminUserEmails(
        emailsToUpdate, this.company.parseObject, adminUserType
      );

      // Check if the current user was removed, if so logout
      if (!move && emailsToUpdate.remove.includes(UserService.getCurrentUser().get('username'))) {
        UserService.logout();
      }

      this.saveToStore(adminUserType, emailsToUpdate);
      this.$store.dispatch('showSnackBar', { text: 'The admins have been saved.' });
      this.loading[adminUserType] = false;
    },
    async moveEmailFromTo(fromType, toType, email) {
      const fromTypeEmails = this.company[fromType] || [];
      const toTypeEmails = this.company[toType] || [];
      if (!fromTypeEmails.includes(email)) {
        this.$store.dispatch('showSnackBar', { text: 'Please save first.' });
        return;
      }
      if (fromType === ADMIN_ROLES.COMPANY_ADMINS && this.company.admins.length <= 1) {
        this.$store.dispatch('showSnackBar', { text: 'There should be at least 1 Company Admin.' });
        return;
      }
      const move = true;
      const toFunctionName = this.isCoach(toType) ? 'saveCoaches' : 'save';
      await this[toFunctionName](toType, {
        all: toTypeEmails.concat([email]),
        remove: [],
        add: [email]
      }, move);
      const fromFunctionName = this.isCoach(fromType) ? 'saveCoaches' : 'save';
      await this[fromFunctionName](fromType, {
        all: fromTypeEmails.filter((adminUser) => adminUser !== email),
        remove: [email],
        add: []
      }, move);

      // Check if the current user was removed, if so logout
      if (email === UserService.getCurrentUser().get('username')) {
        UserService.logout();
      }
    },
    checkIfEmailsExists(emails, additionalCheck = []) {
      if (!Array.isArray(emails)) {
        // eslint-disable-next-line no-param-reassign
        emails = [emails];
      }
      const companyAdmins = this.company.admins || [];
      const unitAdmins = this.company.unitAdmins || [];
      const coaches = this.company.coaches || [];

      const currentAdminUsers = [...companyAdmins, ...unitAdmins, ...coaches, ...additionalCheck];
      return emails.some((e) => currentAdminUsers.includes(e));
    }
  }
};
