<template>
  <v-menu
    bottom
    left
    offset-y
    rounded="lg"
    nudge-bottom="25"
    nudge-left="10"
    min-width="200"
    z-index="10"
    class="header-container pl-2"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        class="ml-2"
        v-on="on"
      >
        <img
          :src="arrowIconUrl"
          class="user-menu"
          alt="arrow"
        >
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="option in headerMenuOptions"
        :key="option.key"
        @click="menuActions(option.key)"
      >
        <v-list-item-icon class="menu-header-icon">
          <v-icon>{{ option.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ option.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import arrowIcon from '../assets/img/arrow-icon.svg';
import UserService from '../services/userService';

export default {
  name: 'LayoutUserHeader',
  props: {
    portalUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      arrowIconUrl: arrowIcon,
      headerMenuOptions: [
        {
          icon: '$pagerIcon',
          text: 'Go to Portal',
          key: 'portal'
        },
        {
          icon: '$logout',
          text: 'Log Out',
          key: 'logout'
        },
      ]
    };
  },
  methods: {
    menuActions(key) {
      switch (key) {
        case 'portal':
          window.open(`${this.portalUrl}/#/`, '_blank');
          break;
        case 'logout':
          UserService.logout();
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.user-menu {
  cursor: pointer;
}
.menu-header-icon {
  margin: auto 14px !important;
}
</style>
