<template>
  <v-card class="pt-2">
    <v-card-text>
      <slot />

      <v-text-field
        ref="emails"
        v-model="email"
        v-ruid="'admins'"
        :label="label"
        :rules="validations"
        class="mt-4 mb-1"
        clearable
        hint="Press Enter to add."
        placeholder="Add an email"
        required
        @keypress.enter="addEmail(email, allowExternalEmails)"
      />
      <v-card>
        <v-list v-if="emails && emails.length > 0">
          <v-list-item
            v-for="emailAddress in emails"
            :key="emailAddress"
            class="tile-container"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ emailAddress }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in listItemActions"
                    :key="index"
                    @click="item.onClick(emailAddress)"
                  >
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="deleteEmail(emailAddress)"
                  >
                    <v-list-item-title class="delete">
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <div>
        <app-button
          v-ruid="'saveAdmin'"
          :loading="isLoading"
          class="my-4"
          @click="$emit('save', emailsToUpdate)"
        >
          Save
        </app-button>
      </div>
      <label
        v-if="minCount > 0"
        class="body-1"
      >* Mandatory field</label>
    </v-card-text>
  </v-card>
</template>

<script>
import ValidateMixin from '../mixins/validateMixin';

export default {
  mixins: [ValidateMixin],
  props: {
    adminUserEmails: {
      type: Array,
      required: true
    },
    domains: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    listItemActions: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    allowExternalEmails: {
      type: Boolean,
      default: false
    },
    minCount: {
      type: Number,
      default: 0
    },
    checkIfEmailsExists: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      email: '',
      emails: []
    };
  },
  computed: {
    emailsToUpdate() {
      const emailsToRemove = this.$lodash.difference(this.adminUserEmails, this.emails);
      const emailsToAdd = this.$lodash.difference(this.emails, this.adminUserEmails);
      return {
        all: this.emails,
        remove: emailsToRemove,
        add: emailsToAdd
      };
    },
    validations() {
      if (this.minCount > 0) {
        return [this.rules.atLeastOneItem(this.emails)];
      }
      return [];
    }
  },
  watch: {
    adminUserEmails() {
      this.setEmails();
    }
  },
  async created() {
    this.setEmails();
  },
  methods: {
    setEmails() {
      this.emails = [...this.adminUserEmails];
    },
    addEmail(email, allowExternalEmails = false) {
      if (email) {
        if (!this.checkEmail(email)) {
          this.$store.dispatch('showSnackBar', { text: 'The email is not valid.' });
          return;
        }
        const newEmail = email.toLowerCase();
        const emailDomain = newEmail.split('@')[1];
        const emailExists = this.checkIfEmailsExists(newEmail, this.emails);
        const validDomain = this.domains.some((d) => d === emailDomain);
        if (!allowExternalEmails && !validDomain) {
          this.$store.dispatch('showSnackBar', { text: 'The domain is not valid, please use a company domain.' });
          return;
        }
        if (emailExists) {
          this.$store.dispatch('showSnackBar', { text: 'This email is already an Admin User.' });
        } else {
          this.emails.push(newEmail);
        }
        this.email = '';
      }
    },
    deleteEmail(email) {
      if (this.emails.length > this.minCount) {
        const index = this.emails.indexOf(email);
        this.emails.splice(index, 1);
      } else {
        this.$store.dispatch('showSnackBar', { text: 'There should be at least 1 Company Admin.' });
      }
    }
  }
};
</script>

<style scoped>
.tile-container:not(:last-child) {
  border-bottom: 1px solid #d0cbcb !important;
}

.delete {
  color: #ff4a4a;
}
</style>
