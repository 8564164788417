import Parse from 'parse';

const {
  VUE_APP_PARSE_APPLICATION_ID,
  VUE_APP_PARSE_JAVASCRIPT_KEY,
  VUE_APP_PARSE_ENABLE_UNSAFE_CURRENT_USER,
  VUE_APP_PARSE_SERVER_URL,
} = process.env;

if (
  !VUE_APP_PARSE_APPLICATION_ID
  || !VUE_APP_PARSE_JAVASCRIPT_KEY
  || !VUE_APP_PARSE_SERVER_URL) {
  throw new Error('[common-util] invalid Parse env vars,'
    + 'check your .env.development or production env vars for VUE_APP_PARSE_** config');
}
Parse.initialize(VUE_APP_PARSE_APPLICATION_ID, VUE_APP_PARSE_JAVASCRIPT_KEY);
Parse.serverURL = VUE_APP_PARSE_SERVER_URL;
if (VUE_APP_PARSE_ENABLE_UNSAFE_CURRENT_USER) {
  Parse.User.enableUnsafeCurrentUser();
}

export default Parse;
