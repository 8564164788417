module.exports = {
  TYPES: {
    BEST_PRACTICE: 'best-practice',
    SERVICE: 'service'
  },
  TAGS: [
    {
      key: 'workshops',
      title: 'WORKSHOPS',
    },
    {
      key: 'coaching',
      title: 'COACHING',
    },
    {
      key: 'coach_training',
      title: 'COACH TRAINING',
    },
    {
      key: 'others',
      title: 'OTHERS',
    },
  ],
  PEOPLE: [
    {
      id: 0,
      name: 'your KICKBOX Sparring Partner',
      email: 'cs@rready.com',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Services.png',
      company: 'RREADY',
    },
    {
      id: 1,
      name: 'Lorena Ricci',
      email: 'lorena@rready.com',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Lorena.jpg',
      company: 'RREADY',
    },
    {
      id: 2,
      name: 'Gion Tuor',
      email: 'gion@rready.com',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Gion.jpg',
      company: 'RREADY',
    },
    {
      id: 3,
      name: 'Jemma Cardy',
      email: 'jemma@rready.com',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Jemma.jpg',
      company: 'RREADY',
    },
    {
      id: 4,
      name: 'Reto Wenger',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Reto.jpg'
    },
    {
      id: 5,
      name: 'Vinz Leuenberger',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Vinz.jpg'
    },
    {
      id: 6,
      name: 'Dave Hengartner',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Dave.jpg'
    },
    {
      id: 7,
      name: 'Ralph Hartmeier',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Ralph.jpg'
    },
    {
      id: 8,
      name: 'Fenja Persello',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Fenja.jpg'
    },
    {
      id: 9,
      name: 'Adriana Rocha',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Adriana.jpg'
    },
    {
      id: 10,
      name: 'Celia Geering',
      company: 'RREADY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Celia.jpg'
    },
    {
      id: 11,
      name: 'Michael Hunkeler',
      company: 'SWISSCOM',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Michael.jpg'
    },
    {
      id: 12,
      name: 'David Camacho',
      company: 'SIEMENS ENERGY',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/David.jpeg'
    },
    {
      id: 13,
      name: 'Lisa Nyffeler',
      email: 'lisa@rready.com',
      photo: 'https://kickbox-files-prod.s3.eu-central-1.amazonaws.com/file-sharing/innovation-hub/people/Lisa.jpeg',
      company: 'RREADY',
    }
  ]
};
