import Parse from '@kickbox/common-util/src/parse';
import { instance, query } from '@kickbox/common-util/src/util/parse';

const formatContent = (c) => ({
  name: c.get('name'),
  text: c.get('text'),
  language: c.get('language'),
  default: c.get('default'),
  customEnabled: c.get('customEnabled'),
  subject: c.get('subject') || '',
  title: c.get('title'),
  parseObject: c
});

export default {
  getContent(contentName, company, language) {
    return this.getCustomContent([contentName], company, true)
      .then((customContent) => {
        if (customContent.length > 0) {
          const customLanguageContent = this.getLanguageContent(customContent, language);
          if (customLanguageContent) {
            return customLanguageContent;
          }
        }
        return this.getDefaultContent([contentName])
          .then((defaultContent) => {
            const defaultLanguageContent = this.getLanguageContent(defaultContent, language);
            return defaultLanguageContent || this.getLanguageContent(defaultContent, 'EN');
          });
      });
  },
  getLanguageContent(contentArray, language) {
    return contentArray.find((c) => c.language === language);
  },
  getDefaultContent(contentNameArray) {
    return query('Content')
      .equalTo('default', true)
      .containedIn('name', contentNameArray)
      .limit(10000)
      .find()
      .then((content) => content.map(formatContent));
  },
  getCustomContent(contentNameArray, company, customEnabled) {
    const customContentQuery = query('Content')
      .equalTo('default', false)
      .equalTo('company', company)
      .containedIn('name', contentNameArray);
    if (customEnabled) {
      customContentQuery.equalTo('customEnabled', customEnabled);
    }
    return customContentQuery.find()
      .then((content) => content.map(formatContent));
  },
  getCampaignContent(company) {
    return query('Content')
      .equalTo('company', company)
      .equalTo('name', 'campaign_settings')
      .find()
      .then((content) => content.map(formatContent));
  },
  addContent(properties) {
    return instance('Content', properties).save();
  },
  updateContent(content) {
    return content.parseObject.save(content.properties);
  },
  deleteContent(content) {
    return content.parseObject.destroy();
  },
  manageContent(properties, contentStore) {
    if (!properties.name) {
      return false;
    }

    const contentExist = contentStore.find((ca) => (
      ca.name === properties.name && ca.language === properties.language
    ));

    if (contentExist.parseObject) {
      const content = { properties, parseObject: contentExist.parseObject };
      return this.updateContent(content)
        .then(() => ({
          content: content.properties
        }));
    }

    return this.addContent(properties)
      .then((contentObject) => {
        const content = Object.assign(properties, { parseObject: contentObject });
        return {
          content
        };
      });
  },
  sendContentPreviewEmail(properties) {
    return Parse.Cloud.run('sendEmail', properties);
  }
};
