<template>
  <div>
    <v-tabs
      v-model="active"
      background-color="primary"
      slider-color="white"
    >
      <v-tab>
        Internal coaches
      </v-tab>
      <v-tab>
        External coaches
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="active">
      <v-tab-item>
        <admin-user-list
          :admin-user-emails="coaches[ADMIN_ROLES.INTERNAL_COACHES] || []"
          :allow-external-emails="true"
          :check-if-emails-exists="checkIfEmailsExists"
          :domains="domains"
          :is-loading="loading"
          :list-item-actions="listItemActions[ADMIN_ROLES.INTERNAL_COACHES]"
          label="Coaches"
          @save="$emit('save', { type: ADMIN_ROLES.INTERNAL_COACHES, emailsToUpdate: $event })"
        >
          <p class="info-header">
            Coaches are guiding a project through the process and have only access to data
            of projects they are assigned to.
            <br>
            Internal coaches are users from inside the organization.
          </p>
        </admin-user-list>
      </v-tab-item>
      <v-tab-item>
        <admin-user-list
          :admin-user-emails="coaches[ADMIN_ROLES.EXTERNAL_COACHES] || []"
          :allow-external-emails="true"
          :check-if-emails-exists="checkIfEmailsExists"
          :domains="domains"
          :is-loading="loading"
          :list-item-actions="listItemActions[ADMIN_ROLES.EXTERNAL_COACHES]"
          label="Coaches"
          @save="$emit('save', { type: ADMIN_ROLES.EXTERNAL_COACHES, emailsToUpdate: $event })"
        >
          <p class="info-header">
            Coaches are guiding a project through the process and have only access to data
            of projects they are assigned to.
            <br>
            External coaches are users from outside the organization.
          </p>
        </admin-user-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import AdminUserList from './AdminUserList';

export default {
  name: 'AdminUsers',
  components: {
    AdminUserList
  },
  props: {
    coaches: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    domains: {
      type: Array,
      required: true
    },
    listItemActions: {
      type: Object,
      required: true
    },
    checkIfEmailsExists: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      ADMIN_ROLES,
      active: 0
    };
  }
};
</script>

<style lang="scss" scoped>
.info-header {
  font-size: 13px;
  font-weight: 500;
  color: #4d4d4d;
}
::v-deep.v-tab:not(.v-tab--active) {
  color: hsla(0,0%,100%,.5) !important;
}
</style>
