import { ADMIN_ROLES } from '../../constants/admin-roles';

/**
 * Map the ADMIN_ROLES constant to readable format and camel case format
 *
 * @param {Object} adminUserType The Campaign object
 * @param {Boolean} camelCase Company language
 *
 * @returns {String}
 */
export function adminTypeToString(adminUserType, camelCase = false) {
  switch (adminUserType) {
    case ADMIN_ROLES.COMPANY_ADMINS:
      return camelCase ? 'companyAdmin' : 'Company Admin';
    case ADMIN_ROLES.UNIT_ADMINS:
      return camelCase ? 'unitAdmin' : 'Unit Admin';
    case ADMIN_ROLES.COACHES:
      return camelCase ? 'coach' : 'Coach';
    default:
      throw Error(`adminUserType ${adminUserType} not known`);
  }
};
