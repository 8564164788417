import { KICKBOX_ROLES } from '@kickbox/common-util';
import Parse from '@kickbox/common-util/src/parse';

export default {
  async updateAdminsUsersRole(companyId, addUsers, removeUsers, setAdminRole) {
    if (addUsers.length || removeUsers.length) {
      await Parse.Cloud.run('setRoles', { companyId, addUsers, removeUsers, setAdminRole });
    }
  },

  queryForRole(role) {
    return new Parse.Query(Parse.Object.extend(Parse.Role))
      .equalTo('name', role)
      .first();
  },

  addViewerRoleToUsers(addUsersArray) {
    return this.addRoleToUsers(addUsersArray, KICKBOX_ROLES.VIEWER);
  },

  async addRoleToUsers(usersArray, roleToAdd) {
    return Parse.Cloud.run('assignRoleToUser', { users: usersArray, role: roleToAdd });
  }
};
