import Parse from '@kickbox/common-util/src/parse';
import fileService from '@kickbox/common-util/service/fileService';

class EntityService {
  static async updateEntity(entityType, id, setProperties, unsetProperties, imageProperty) {
    await this.processImage(imageProperty, entityType, id, setProperties);
    return Parse.Cloud.run('updateEntity', { entityType, id, setProperties, unsetProperties })
      .catch((error) => {
        console.log('updateEntity', error);
      });
  }

  static async processImage(imageProperty, entityType, id, setProperties) {
    if (imageProperty) {
      const field = this.getPicturePropertyName(entityType);
      const objectInfo = {
        id,
        field,
        type: entityType
      };
      const location = await fileService
        .uploadImage(imageProperty.file, imageProperty.oldUrl, 'public-read', objectInfo);
      // eslint-disable-next-line no-param-reassign
      setProperties[field] = location;
    }
  }

  static getPicturePropertyName(entityName) {
    return entityName === 'Company' ? 'logo' : 'photo';
  }
}

export default EntityService;
