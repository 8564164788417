import Parse from '@kickbox/common-util/src/parse';

export default {
  async getLanguages(companyLanguages = null) {
    const query = new Parse.Query('Language');
    if (companyLanguages) {
      query.containedIn('key', companyLanguages);
    }
    const languageObjects = await query.find();

    return languageObjects.map(this.convertLanguageObj);
  },
  convertLanguageObj(language) {
    return {
      id: language.id,
      key: language.get('key'),
      name: language.get('name'),
      defaultLanguage: language.get('defaultLanguage')
    };
  }
};
