var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"content-card-padding"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('app-button',{attrs:{"secondary":""},on:{"click":function($event){_vm.showEditDialog = true}}},[_vm._v(" Add tag ")]),_c('download-excel',{attrs:{"data":_vm.searchResult,"fields":_vm.exportDataFields,"name":"download.xls"}},[_c('app-button',{staticClass:"ml-4",attrs:{"secondary":"","disabled":_vm.searchResult.length === 0}},[_vm._v(" Export List ")])],1)],1),_c('v-data-table',{staticClass:"table-container",attrs:{"footer-props":{
        itemsPerPageText: 'Tags per page:',
        itemsPerPageOptions: [15, 30, 45, { text: 'All', value: -1 }]
      },"headers":_vm.headers,"items":_vm.tags,"search":_vm.search,"custom-filter":_vm.customFilter,"custom-sort":_vm.customSort,"no-data-text":_vm.noData,"item-key":"creationDate"},scopedSlots:_vm._u([(_vm.isLoading.tags)?{key:"body",fn:function(){return [_c('table-skeleton-loader',{attrs:{"headers":_vm.headers}})]},proxy:true}:{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),(!item.disabled)?_c('td',[_c('v-icon',{attrs:{"green":""}},[_vm._v(" check_circle ")])],1):_c('td',[_c('v-icon',[_vm._v("block")])],1),_c('td',[_vm._v(_vm._s(_vm.projectCount(item.id)))]),_c('td',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editTagDetails(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),(!_vm.isLastActiveTag(item))?_c('v-list-item',{on:{"click":function($event){return _vm.removeTagWarning(item)}}},[_c('v-list-item-title',[_vm._v("Remove")])],1):_vm._e()],1)],1)],1)])]}}],null,true)})],1),(_vm.showEditDialog)?_c('manage-tag-dialog',{attrs:{"show-dialog":_vm.showEditDialog,"prevent-disable":_vm.preventDisable(_vm.tag),"tags":_vm.tags,"tag":_vm.tag},on:{"close-dialog":_vm.closeTagDialog,"save":_vm.saveTag}}):_vm._e(),(_vm.showDeleteTagDialog)?_c('confirm-dialog',{attrs:{"show":_vm.showDeleteTagDialog,"confirm-click":_vm.removeTag},on:{"close":function($event){_vm.showDeleteTagDialog = false}}},[_vm._v(" Are you sure you want to delete this tag: \""+_vm._s(_vm.tag.name)+"\"? ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }