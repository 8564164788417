<template>
  <v-row
    v-if="showSendPreview"
    class="mt-2 email-preview-container"
  >
    <v-col cols="7">
      <v-text-field
        v-if="showSendPreview"
        v-model="email"
        :disabled="sendButtonDisabled"
        label="Email Preview"
        clearable
        placeholder="Enter an email address"
        class="mt-4"
        hint="The email address needs to be already registered on the platform."
        persistent-hint
      />
    </v-col>
    <v-col>
      <app-button
        secondary
        :disabled="sendButtonDisabled || !email"
        class="my-6"
        @click="send"
      >
        Send
      </app-button>
    </v-col>
  </v-row>
</template>

<script>
import { formValidation } from '@kickbox/common-util';

export default {
  props: [
    'showSendPreview',
    'sendButtonDisabled',
    'preFillEmail'
  ],
  data() {
    return {
      email: ''
    };
  },
  created() {
    if (this.preFillEmail) {
      this.email = this.preFillEmail;
    }
  },
  methods: {
    send() {
      if (!formValidation.emailIsValid(this.email)) {
        return this.$store.dispatch('showSnackBar', { text: 'Enter a valid email address.' });
      }
      return this.$emit('send-preview', this.email);
    }
  }
};
</script>

<style scoped>

</style>
