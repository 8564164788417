const state = {
  navigationDrawer: true
};

const getters = {
  navigationDrawer: (st) => st.navigationDrawer
};

const mutations = {
  setNavigationDrawer(st, payload) {
    st.navigationDrawer = payload;
  }
};

const actions = {
  setNavigationDrawer({ commit }, payload) {
    commit('setNavigationDrawer', payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
