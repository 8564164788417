const testing = !!process.env.VUE_APP_E2E;

const ruid = {
  bind(el, binding) {
    if (!testing) return;

    el.dataset.ruid = binding.value;
  },

  unbind(el) {
    if (!testing) return;

    el.removeAttribute('data-ruid');
  },
};

export default {
  install(Vue) {
    Vue.directive('ruid', ruid);
  },
};
