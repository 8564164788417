module.exports = {
  SSO: 'sso',
  SSO_ADMIN: 'sso_admin',
  MULTI_ENTITY: 'multi_entity',
  PROJECTS: 'projects',
  PEOPLE: 'people',
  SERVICES: 'services',
  CAMPAIGNS: 'campaigns',
  CROSS_CORPORATE: 'cross_corporate',
  EXPLORE_SECTION: 'explore_section',
  HOME_PAGE_KICKBOOK_SECTION: 'home_page_kickbook_section',
  SHOW_BLUE_BOOK: 'show_blue_book',
  COINS: 'coins',
  COIN_CONVERSION: 'coin_conversion',
  TEAM_MEMBERS: 'team_members',
  CHATBOT: 'chatbot',
  COACHING_HUB: 'coaching_hub',
  SHOW_PENDING_PROJECTS: 'show_pending_projects',
  DISABLE_IDEA_SUBMISSION: 'disable_idea_submission',
  CREATE_USERS: 'create_users',
  SEND_MONTHLY_EXPERT_NEWSLETTER: 'send_monthly_expert_newsletter',
  SERVICE_CATEGORIES: 'service_categories',
  REQUESTED_STARTING_MONTH: 'requested_starting_month',
  XMAS_BOXLY: 'xmas_boxly',
  TRANSLATE_USER_CONTENT: 'translate_user_content',
  AUTO_TRANSLATE_USER_CONTENT: 'auto_translate_user_content',
  DISABLE_CHANGE_PHASE_PORTAL: 'disable_change_phase_portal',
  COMMUNITY_BANNER: 'community_banner',
  YOUTUBE_VIDEO: 'youtube_video'
};
