import removeEmptyPropertiesFromObject from '@kickbox/common-util/src/util/removeEmptyPropertiesFromObject';

export default {
  data() {
    return {
      cover: {},
      initialImage: '',
      formHasNoErrors: true,
      rules: {
        required: (value) => !!value || 'The field is required.',
        positiveNumber: (value) => value > 0 || 'Is not a valid number.',
        atLeastOneItem: (value) => (value && value.length > 0) || 'Add at least 1 item.',
        max3Categories: (value) => (value && value.length < 4) || 'Max 3 categories.',
        textExceededCharacters: (value, amount) => (value && value.length <= amount) || `The maximum amount is ${amount} characters`,
        validEmail: (value) => this.checkEmail(value) || 'E-mail must be valid',
        uniqueDomain: (domain, companyDomains) => this.checkDomain(domain, companyDomains) || ''
      }
    };
  },
  methods: {
    verifyFieldsValue(fields) {
      let isValid = true;
      Object.keys(fields)
        .forEach((item) => {
          const validItem = this.validArray(fields, item);
          if (!validItem) isValid = false;
        });
      return isValid;
    },
    checkFields() {
      this.formHasNoErrors = true;
      Object.keys(this.formMandatoryFields)
        .forEach((f) => {
          if (this.$refs[f]) {
            const validateField = this.$refs[f].validate(true);
            if (!validateField) this.formHasNoErrors = false;
            if (!this.formMandatoryFields[f] || this.formMandatoryFields[f].length === 0) {
              this.formHasNoErrors = false;
            }
            const isArray = Array.isArray(this.formMandatoryFields[f]);
            if (isArray && this.formMandatoryFields[f].some((item) => item == null)) {
              this.formHasNoErrors = false;
            }
          }
        });
      return this.formHasNoErrors;
    },
    validArray(array, item) {
      const isArray = Array.isArray(array[item]);
      return !(isArray && array[item].some((i) => i == null));
    },
    resetFieldValidation() {
      Object.keys(this.formMandatoryFields)
        .forEach((f) => {
          if (this.$refs[f]) {
            this.$refs[f].resetFieldValidation();
            this.$refs[f] = null;
          }
        });
      return this.formHasNoErrors;
    },
    checkEmail(emails) {
      const emailArray = Array.isArray(emails) ? emails : [emails];
      const emailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      return emailArray.every((value) => emailPattern.test(value && value.toLowerCase()));
    },
    checkContactInfo(contactInfo) {
      const checkContactInfo = contactInfo.filter((contact) => contact.value && contact.type);
      if (contactInfo.length === 0) {
        this.$store.dispatch('showSnackBar', { text: 'You need to add at least one Contact Info.' });
        return false;
      }
      if (checkContactInfo.length === 0) {
        this.$store.dispatch('showSnackBar', { text: 'You need to add at least one valid Contact Info.' });
        return false;
      }
      return checkContactInfo;
    },
    getValidatedEntityProperties(formFields) {
      const fieldsToSave = Object.keys(formFields);
      const entityProperties = removeEmptyPropertiesFromObject(
        formFields, fieldsToSave
      );
      const filledInFieldNames = Object.keys(entityProperties);
      const unsetEntityProperties = fieldsToSave.filter(
        (field) => !filledInFieldNames.includes(field)
      );
      return {
        setProperties: entityProperties,
        unsetProperties: unsetEntityProperties
      };
    },
    checkDomain(domains, companyDomains) {
      const { companiesDomains } = this.$store.getters;
      const newDomains = this.$lodash.difference(domains, companyDomains);
      newDomains.forEach((d) => {
        const domainExist = companiesDomains.some((cd) => cd === d);
        if (domainExist) {
          this.$store.dispatch('showSnackBar', { text: `The domain ${d} is already used for another company`, timeout: 2800 });
          domains.splice(domains.indexOf(d), 1);
        }
      });
      return true;
    },
    onlyPositiveNumber(e) {
      if (!((e.keyCode > 95 && e.keyCode < 106)
        || (e.keyCode > 47 && e.keyCode < 58)
        || e.keyCode === 8)) {
        e.preventDefault();
      }
    }
  }
};
