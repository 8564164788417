<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      :mobile-breakpoint="1024"
      app
      width="300"
    >
      <router-link
        to="/"
        tag="div"
      >
        <v-avatar
          v-if="company"
          tile
          class="mt-4 logo-container"
        >
          <img
            :src="company.logo"
            class="img"
            alt="logo"
          >
        </v-avatar>
      </router-link>
      <div class="menu-container">
        <v-list>
          <v-list-group
            v-for="item in enabled(items)"
            :key="item.menu"
            :append-icon="item.items ? 'keyboard_arrow_down' : ''"
            no-action
            :to="item.to"
            @click="navigateTo(item)"
            @click.once="groupClicked(item)"
          >
            <template slot="prependIcon">
              <v-icon
                color="primary"
              >
                {{ item.action }}
              </v-icon>
            </template>
            <template #activator>
              <v-list-item-content :class="{'overflow-visible': item.tag}">
                <v-list-item-title>
                  <div class="d-flex align-center font-weight-medium">
                    {{ item.menu }}
                    <v-chip
                      v-if="item.tag"
                      :color="item.tag.color || 'primary'"
                      :class="item.tag.textColor || 'white--text'"
                      class="tag ml-auto no-hover"
                      small
                      outlined
                    >
                      {{ item.tag.text }}
                    </v-chip>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="subItem in enabled(item.items)"
              :key="subItem.value"
              :to="subItem.to"
              class="nav-list-item"
              @click="subItem.onClick && subItem.onClick()"
            >
              <v-list-item-title>
                <div class="d-flex align-center font-weight-medium">
                  {{ subItem.value }}
                  <v-chip
                    v-if="subItem.tag"
                    :color="subItem.tag.color || 'primary'"
                    :class="subItem.tag.textColor || 'white--text'"
                    class="tag my-0 ml-auto mr-4 no-hover"
                    small
                    outlined
                  >
                    {{ subItem.tag.text }}
                  </v-chip>
                  <v-chip
                    v-else-if="badgeAmount(subItem)"
                    color="primary"
                    class="white--text tag my-0 ml-auto mr-4 no-hover"
                    small
                  >
                    {{ badgeAmount(subItem) }}
                  </v-chip>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      color="white"
      fixed
      app
      class="shadow-1"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="font-weight-medium">
        {{ getTitle() }}
      </v-toolbar-title>
      <v-spacer />
      <admin-display
        :user-role="userRole"
        :current-user="currentUser"
      />
      <template v-if="isAdmin">
        <v-icon
          size="20"
          @click="navigateTo({to: {name: 'Help'}})"
        >
          $help
        </v-icon>
        <layout-user-header :portal-url="portalUrl" />
      </template>
    </v-app-bar>

    <v-main>
      <!-- Header Message -->
      <v-expand-transition>
        <v-row
          v-show="headerMessage && !hideHeaderMessage"
          align="center"
          class="flex-nowrap header-banner pl-4 pr-10 pr-sm-8"
          :class="{ drawer }"
          no-gutters
        >
          <v-col
            class="py-2"
            cols="auto"
          >
            <b>{{ headerMessage.title }}</b>
            <br>
            <span>{{ headerMessage.message }}</span>
          </v-col>
          <v-spacer />
          <v-col
            class="flex-shrink-0"
            cols="auto"
          >
            <v-btn
              height="40"
              icon
              width="40"
              @click="hideHeaderMessage = true"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-container
        fluid
        :fill-height="useFillHeight"
        class="pt-8"
      >
        <router-view />
        <app-snack-bar />
      </v-container>
    </v-main>
    <v-footer
      color="white"
      class="shadow-1"
      app
      :fixed="$vuetify.breakpoint.mdAndUp"
    >
      <span>
        <span class="ml-4">v. {{ version }}</span>
        <span class="ml-4">&copy; {{ new Date().getFullYear() }}</span>
      </span>
      <span
        v-if="company"
        class="mr-4"
      >
        <span class="contact-info">
          Contact info:
        </span>
        <span>
          rready AG, Hardturmstrasse 3, CH-8005 Zürich,
        </span>
        <a
          href="mailto:hello@getkickbox.com"
          target="_top"
        >
          hello@getkickbox.com
        </a>
      </span>
      <span>
        <a
          v-if="company"
          class="cky-banner-element mr-3"
        >Cookie Settings
        </a>
        <a
          href="https://getkickbox.slack.com/messages/CCUTQ8VMW/"
          class="slack-link"
        >
          Slack
        </a>
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import Parse from '@kickbox/common-util/src/parse';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import SnackBar from './SnackBar';
import { version } from '../../package';
import AdminDisplay from './AdminDisplay';
import LayoutUserHeader from './LayoutUserHeader';

export default {
  name: 'Layout',
  components: {
    appSnackBar: SnackBar,
    AdminDisplay,
    LayoutUserHeader
  },
  props: ['items', 'amountPending', 'menuNames', 'amountDue', 'amountRejected', 'isAdmin', 'portalUrl'],
  data() {
    return {
      drawer: true,
      userRole: '',
      version,
      currentUser: null,
      hideHeaderMessage: false
    };
  },
  computed: {
    loader() {
      return this.$store.getters.loader;
    },
    company() {
      return this.$store.getters.company;
    },
    /**
     * Builds a header message (if necessary).
     */
    headerMessage() {
      // Check if browser is Internet Explorer
      // https://jsfiddle.net/alvaroAV/svvz7tkn/
      if (navigator.userAgent.includes('MSIE ') || navigator.userAgent.includes('Trident/')) {
        return {
          title: 'This app is not optimized for Internet Explorer.',
          message: 'For best experience, please use Microsoft Edge or Google Chrome.'
        };
      }

      // Check if browser is Mobile
      if (isMobile) {
        return {
          title: 'This app is not optimized for Mobile use.',
          message: 'For best experience, please use the desktop page.'
        };
      }

      return false;
    },
    useFillHeight() {
      return !!this.$route.meta?.fillHeightContainer;
    }
  },
  watch: {
    drawer() {
      // eslint-disable-next-line no-underscore-dangle
      if (this.$store._mutations.setProjectsDrawer) {
        this.$store.commit('setNavigationDrawer', this.drawer);
      }
    }
  },
  created() {
    this.currentUser = Parse.User.current();
    if (this.isAdmin) {
      const userEmail = this.currentUser.get('email');
      if (this.company.admins.includes(userEmail)) {
        this.userRole = ADMIN_ROLES.COMPANY_ADMINS;
      } else if (this.company.unitAdmins.includes(userEmail)) {
        this.userRole = ADMIN_ROLES.UNIT_ADMINS;
      } else if (this.company.coaches) {
        this.userRole = ADMIN_ROLES.COACHES;
      }
    }
  },
  methods: {
    enabled(items) {
      if (this.isAdmin) {
        return items && items.filter((item) => {
          const featureEnabled = !item.featureRequired
            || this.company.features[item.featureRequired];
          const innovationHubMobile = item.to && item.to.name === 'InnovationHub' && isMobile;
          const hideUsersCertification = item.to && item.to.name === 'AdminUsersCertificates' && !this.company.airtableData.id;
          const disabled = item.disabledFor && item.disabledFor.includes(this.userRole);
          return featureEnabled && !disabled && !innovationHubMobile && !hideUsersCertification;
        });
      }
      return items;
    },
    getTitle() {
      return this.menuNames[this.$router.currentRoute.name];
    },
    navigateTo(item) {
      if (item.to) {
        // When you Click the link of your current page, the router throws an error
        // -> catch error so that there is no error in the console
        this.$router.push(item.to)
          .catch(() => {
          });
      }
    },
    groupClicked(item) {
      if (item.items) {
        return null;
      }

      return item.onClick && item.onClick();
    },
    badgeAmount(item) {
      if (item.value === 'Due') return this.amountDue;
      if (item.value === 'Pending') return this.amountPending;
      if (item.value === 'Rejected') return this.amountRejected;
      return false;
    }
  }
};
</script>

<style lang="scss">
@import "~@kickbox/common-admin/src/assets/scss/mixins";
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400,500,600,700);

$font-color: #464646;

.v-application, .v-application .subtitle-1, .subtitle-2, .body-1, .v-application .body-2,
.button, .caption, .v-application .text-caption,
.overline {
  font-family: "Montserrat", "Roboto", sans-serif !important;
  color: $font-color;
}

.theme--light {
  &.v-application, &.v-card {
    color: $font-color;
  }
}

h1, h2, h3 {
  font-weight: 400;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.9rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
}

.v-sheet.v-card:not(.v-sheet--outlined), .v-menu__content, .v-expansion-panel:before, .v-stepper {
  @include shadow(rgb(223, 223, 223));
}

table.table tbody td, table.table tbody th, table.table thead tr, td .menu__activator {
  height: 42px;
}

table.table tbody td .btn {
  margin: 6px 1px;
}

.badge__badge {
  font-size: 11px;
  top: -4px;
  right: -20px;
  height: 18px;
  width: 18px
}

.input-group {
  padding-top: 2px !important;
}

.whitelist-container .table-container {
  min-width: 400px;
}

.whitelist-container .datatable__actions__select .input-group--select {
  margin-left: 13px !important;
}

.badge_format {
  padding-right: 1px;
  padding-top: 1px;
}

.contact-container .input-group__details,
.whitelist-container .input-group__details {
  min-height: 10px;
}

.avatar-container .picture-inner {
  top: -153px !important;
  font-size: 9px !important;
}

.avatar-container .preview-container {
  margin: 15px auto !important;
}

.avatar-container .removeButton {
  padding: 0 10px;
}

.avatar-container .badge__badge {
  top: 60px;
  right: 5px;
  height: 24px;
  width: 24px
}

.input-group--text-field label {
  top: 4px !important;
}

.croppa-container svg.icon-remove {
  top: -10.75px !important;
  right: -10.5px !important;
  z-index: 1;
}

.tile-container .list__tile {
  height: 42px !important;
}

.transactions-container .transaction-table .list__tile {
  height: 100% !important;
}

.video_container iframe {
  width: 100% !important;
  height: 100% !important;
  min-height: 250px !important;
}

.v-avatar img {
  width: auto !important;
}

.avatar--tile {
  width: auto !important;
  height: auto !important;
}

.avatar img {
  max-width: 120px !important;
}

.pending-expansion-panel .expansion-panel__header {
  padding: 0 !important;
  width: 100px !important;
}

.pending-project-container .default-video {
  padding-left: 30px !important;
}

.pending-project-container .default-video label {
  font-size: 14px !important;
  padding-left: 25px;
}

table.table .input-group--selection-controls.checkbox .icon {
  left: 2% !important;
}

.hidden {
  display: none !important;
}

.contact {
  height: 140px;
  position: absolute;
  bottom: 50px;
}

.contact p:first-child {
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.contact p, .contact a {
  float: right;
  clear: both;
  color: gray;
  margin: 0;
}

.v-main {
  // Needs to have a min height, so that the footer is far enought down
  min-height: calc(100vh - 56px);
}

.v-footer {
  justify-content: space-between;

  .contact-info {
    font-weight: bold;
  }
}

.slack-link {
  margin-right: 10px;
}

.logo-container {
  width: 100% !important;
  padding: 0 16px;
  cursor: pointer;
}

.input-group--select .input-group__selections {
  overflow: visible !important;
}

.btn-export {
  @include primaryButton();
}

.overflow-visible {
  overflow: visible;
}

.nav-text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.list-item {
  height: 1.5rem;
}

.list-item-text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.nav-list-item {
  padding: 2px 0;
}

.header-banner {
  background: #FDF6F6;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 11;

  transition-property: width;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);

  @media screen and (min-width: 1024px) {
    &.drawer {
      width: calc(100% - 300px);
    }
  }
}

.v-chip.no-hover {
  cursor: inherit;

  &:hover::before {
    background-color: inherit;
  }
}

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 6.5rem);

  .help-text {
    color: #2b94fc;
    font-weight: 600;
  }
}
</style>
