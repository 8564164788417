const FEATURE_NAMES = require('./feature-names');

module.exports = {
  PAGES: {
    COINS_INSTRUCTIONS: {
      key: 'coins_instructions', name: 'Coins instructions', description: '', features: [FEATURE_NAMES.COIN_CONVERSION, FEATURE_NAMES.COINS]
    },
    THANK_YOU: {
      key: 'thank_you', name: '"Thank you"-page', description: 'This page will be displayed after creating a project.', toolbar: [[{ header: 1 }]]
    },
    PROJECT_AGREEMENT: {
      key: 'project_agreement', name: 'Project agreement', description: ''
    },
    PROJECT_REJECT_MESSAGE: {
      key: 'project_reject_message', name: 'Project reject message', description: ''
    },
    PROJECT_APPROVE_MESSAGE: {
      key: 'project_approve_message', name: 'Project approve message', description: ''
    },
    PROJECT_UNAPPROVE_MESSAGE: {
      key: 'project_unapprove_message', name: 'Project unapprove message', description: ''
    },
    USER_PROJECT_CREATED: {
      key: 'user_project_created', name: 'Project created email to the user', description: ''
    },
    WELCOME_MESSAGE: {
      key: 'welcome_message', name: 'Welcome message', description: ''
    },
    PROJECT_ALUMNI_MESSAGE: {
      key: 'project_alumni_message', name: 'Project alumni message', description: ''
    },
    PROJECT_BACK_FROM_ALUMNI_MESSAGE: {
      key: 'project_back_from_alumni_message', name: 'Project back from alumni message', description: ''
    }
  },
  EMAILS: {
    'CAMPAINNEWPROJECT-1': { key: 'campainNewProject-1', name: 'Email coaching "new project" #1', description: '' },
    'CAMPAINNEWPROJECT-2': { key: 'campainNewProject-2', name: 'Email coaching "new project" #2', description: '' },
    'CAMPAINNEWPROJECT-3': { key: 'campainNewProject-3', name: 'Email coaching "new project" #3', description: '' },
    'CAMPAINNEWPROJECT-4': { key: 'campainNewProject-4', name: 'Email coaching "new project" #4', description: '' },
    'CAMPAINNEWPROJECT-5': { key: 'campainNewProject-5', name: 'Email coaching "new project" #5', description: '' },
    'CAMPAINNEWPROJECT-6': { key: 'campainNewProject-6', name: 'Email coaching "new project" #6', description: '' }
  }
};
