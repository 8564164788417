<template>
  <div>
    <!-- Key Metrics -->
    <div class="key-metrics">
      <key-metric
        v-for="keyMetric of keyMetrics"
        :key="keyMetric.value"
        :number-of-key-metrics="keyMetrics.length"
        :key-metric="keyMetric"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KeyMetric from './KeyMetric';
import utils from './utils';

export default {
  components: {
    KeyMetric
  },
  computed: {
    ...mapGetters(['dashboardProjects', 'userSettings']),
    /**
     * Checks if the user has personalized the key-metrics. If so it returns the key-metrics from
     * the user settings and counts the amount of project for each key-metric.
     *
     * If the user has not personalized it returns the default key-metrics
     */
    keyMetrics() {
      if (this.userSettings && this.userSettings.keyMetrics) {
        const keyMetrics = this.userSettings.keyMetrics.map((keyMetric) => {
          let color = null;
          let tooltip = 'Projects that are currently in ';
          if (keyMetric.type === 'phase') {
            color = utils.phases[keyMetric.value].color;
            tooltip += `${utils.phases[keyMetric.value].title} phase`;
          } else {
            color = '#7EBAC4';
            tooltip += `the "${keyMetric.text}" campaign`;
          }
          return {
            ...keyMetric,
            tooltip,
            color
          };
        });
        return keyMetrics;
      }

      return Object.keys(utils.phases).map((key) => ({
        text: utils.phases[key].title,
        icon: utils.phases[key].icon,
        tooltip: `Projects that are currently in ${utils.phases[key].title} Phase`,
        color: utils.phases[key].color,
        type: 'phase',
        value: utils.phases[key].name
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.key-metrics {
  display: flex;
  flex-wrap: wrap;
}
</style>
