<template>
  <v-btn
    :class="[{ 'shadow': !$attrs.disabled }, 'px-6', 'font-weight-bold', 'base-button']"
    height="50"
    :color="$attrs.color || (cancel ? 'error' : 'primary')"
    :outlined="secondary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  letter-spacing: normal;
}

.shadow {
  @include shadow(rgba(43, 148, 252, 0.2));
}
</style>
