<template>
  <!--  The class "unit-container-${name}" is needed to identify the element using E2E-->
  <v-card
    class="mb-4 pa-3"
    :class="`unit-container-${name}`"
  >
    <v-text-field
      v-model="name"
      :rules="[() => !!name || 'This field is required']"
      label="Unit*"
      clearable
      placeholder="Enter a unit name"
      class="mt-3"
      required
      @input="updateUnit"
    />
    <v-autocomplete
      v-if="canEditAdmins"
      ref="enabledLanguages"
      v-model="selectedAdmins"
      v-ruid="'admins'"
      :items="admins"
      label="Admins"
      placeholder="Add or remove an admin"
      multiple
      chips
      clearable
      class="mt-3"
      item-text="name"
      item-value="key"
      @change="updateUnit"
    >
      <template #prepend-item>
        <v-list-item
          ripple
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon color="primary">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Select All</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" />
      </template>

      <template
        slot="selection"
        slot-scope="data"
      >
        <v-chip
          :input-value="data.selected"
          close
          small
          @click:close="removeChip(selectedAdmins, data.index)"
        >
          {{ data.item }}
        </v-chip>
      </template>
    </v-autocomplete>
    <div class="text-right">
      <v-btn
        icon
        class="ma-0"
        @click="removeUnit"
      >
        <v-icon color="grey">
          delete
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import ValidateMixin from '../mixins/validateMixin';

export default {
  mixins: [ValidateMixin],
  props: {
    unit: {
      type: Object,
      default: () => {
      }
    },
    admins: {
      type: Array,
      default: () => []
    },
    canEditAdmins: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      id: '',
      name: '',
      selectedAdmins: [],
      active: true
    };
  },
  computed: {
    allAdminsSelected() {
      return this.selectedAdmins.length === this.admins.length;
    },
    icon() {
      if (this.allAdminsSelected) {
        return 'check_box';
      }
      return 'check_box_outline_blank';
    },
    currentUnit() {
      return {
        id: this.id,
        name: this.name,
        admins: this.selectedAdmins,
        active: true
      };
    }
  },
  watch: {
    unit() {
      this.setUnit();
    }
  },
  created() {
    this.setUnit();
  },
  methods: {
    setUnit() {
      this.id = this.unit.id;
      this.name = this.unit.name;
      this.selectedAdmins = this.unit.admins || [];
      this.active = this.unit.active;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allAdminsSelected) {
          this.selectedAdmins = [];
        } else {
          this.selectedAdmins = this.admins.slice();
        }
        this.updateUnit();
      });
    },
    removeChip(admins, index) {
      admins.splice(index, 1);
      this.updateUnit();
    },
    updateUnit() {
      this.$emit('input', this.currentUnit);
    },
    removeUnit() {
      this.$emit('remove', this.currentUnit);
    }
  }
};
</script>
