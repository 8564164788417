var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"content-card-padding"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"mb-6 px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Search","append-icon":"search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.pagination.search),callback:function ($$v) {_vm.$set(_vm.pagination, "search", $$v)},expression:"pagination.search"}},on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.searchLabel))])]),_c('v-spacer'),_c('app-button',{on:{"click":function($event){return _vm.buttonClick()}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1),(_vm.loader)?_c('v-progress-linear',{staticClass:"mb-6",attrs:{"indeterminate":_vm.loader,"height":"2"}}):_vm._e(),_c('v-data-iterator',{attrs:{"items":_vm.companies,"search":_vm.pagination.search,"custom-filter":_vm.customFilter,"no-data-text":_vm.noData,"options":_vm.pagination,"items-per-page":12,"footer-props":{
            itemsPerPageOptions: _vm.pagination.rowsPerPageItems
          },"row":"","wrap":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"3"}},[_c('v-card',{staticClass:"mb-4 card",attrs:{"to":(_vm.editPath + "/" + (item.slug || item.id)),"hover":"","max-width":"300"}},[_c('v-img',{class:'card-logo',attrs:{"src":_vm.getUserImage(item.logo),"contain":""}}),(item.domain)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-card-title',_vm._g({directives:[{name:"ruid",rawName:"v-ruid",value:(("domain@" + (item.domain[0]))),expression:"`domain@${item.domain[0]}`"}],staticClass:"text-center d-block grey--text text--darken-2 py-0 ellipsis body-1"},on),[_vm._v(" @"+_vm._s(item.domain[0])+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.formatHtmlDomain(item.domain)))}})]):_vm._e(),(!item.domain)?_c('v-card-title',{staticClass:"text-center d-block pt-4 ellipsis subtitle-2"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.email)?_c('v-card-title',_vm._g({staticClass:" text-center grey--text text--darken-2 py-0 mb-2 ellipsis body-2"},on),[_vm._v(" "+_vm._s(_vm.formatItemEmail(item.email))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formatItemEmail(item.email)))])])],1)],1)}),1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }