<template>
  <v-row v-if="variables.length">
    <v-col
      cols="12"
      class="pb-0"
    >
      <label>To add custom content for your email click on the options
        below to add it in the email content area.
      </label>
    </v-col>
    <v-col>
      <v-chip
        v-for="variable in variables"
        :key="variable.key"
        class="mr-2"
        link
        @click="addVariable(variable.key)"
      >
        {{ variable.text }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EditorVariablesSlot',
  props: {
    variables: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    addVariable(text) {
      const insertTxt = `{{${text}}} `;
      // Get the editor object
      const { quill } = this.$parent.$refs.editorBase;
      // Get the editor cursor position
      const length = quill.selection.savedRange.index;
      // Insert text to the cursor position, insertTxt is the text to be inserted
      quill.insertEmbed(length, insertTxt);
      // Move the cursor to behind the text
      quill.setSelection(length + insertTxt.length);
    }
  }
};
</script>

<style scoped>
  label, ::v-deep .v-chip__content {
    font-size: 12px;
  }
</style>
